<template>
  <div class="history-details-default-container">
    <div class="text-left break-all">
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Destination Account:</span>
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ order.details.destination_account }}</span>
    </div>
    <div class="text-left break-all">
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Source Account:</span>
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ order.details.source_account }}</span>
    </div>
    <div class="text-left break-all">
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">FEE:</span>
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ feeFormatted }} {{ order.currency }}</span>
    </div>
    <div class="text-left break-all" v-if="order.details.comment">
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Comment:</span>
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ order.details.comment }}</span>
    </div>
    <div class="text-left break-all">
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">External ID:</span>
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ order.external_id }}</span>
    </div>
    <div class="text-left break-all">
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Internal ID:</span>
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ order.internal_id }}</span>
    </div>
    <div
      class="text-left break-all"
      v-if="formatedAccauntBalance"
    >
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Account balance:</span>
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ formatedAccauntBalance }}</span>
    </div>
  </div>
</template>

<script>
import { roundingCurrency } from '@/utils/helpers';

export default {
  props: {
    order: {
      type: [Object, Array],
      required: true,
    },
  },
  computed: {
    feeFormatted() {
      return roundingCurrency({
        currencyAmount: this.order.fee,
        currency: this.order.currency,
      })
    },
    formatedAccauntBalance() {
      if(this.order.account_balance) {
        return Object.entries(this.order.account_balance).map(([currency, currencyAmount]) => `${roundingCurrency({ currencyAmount, currency })} ${currency}`).join(', ')
      }

      return false
    },
  },
};
</script>
