<template>
  <div class="HistoryDetails_container HistoryDetails_container--grid">
    <div class="HistoryDetails_col">      
      <div class="HistoryDetails_row">
        <span class="HistoryDetails_label">Internal id:</span
        ><span class="HistoryDetails_value">{{ order.internal_id }}</span>
      </div>
      <div class="HistoryDetails_row">
        <span class="HistoryDetails_label">Transfer hash: </span>
        <a
          class="HistoryDetails_value HistoryDetails_value--link"
          :href="details.tr_hash"
          target="_blank"
        >
          link hash transaction
        </a>
      </div>
      <div class="HistoryDetails_row">
        <span class="HistoryDetails_label">Date added:</span><span class="HistoryDetails_value">{{ details.date_added }}</span>
      </div>
      <div class="HistoryDetails_row">
        <span class="HistoryDetails_label">Amount USD:</span><span class="HistoryDetails_value">{{ details.amount_in_usd }}</span>
      </div>
      <div class="HistoryDetails_row">
        <span class="HistoryDetails_label">Risky amount USD:</span
        ><span class="HistoryDetails_value">{{ details.risky_volume_in_usd }}</span>
      </div>
      <div class="HistoryDetails_row">
        <span class="HistoryDetails_label">Fee:</span
        ><span class="HistoryDetails_value">{{ feeFormatted }} {{ order.currency }} </span>
      </div>
      <div class="HistoryDetails_row" v-if="formatedAccauntBalance">
        <span class="HistoryDetails_label">Account balance:</span
        ><span class="HistoryDetails_value">{{ formatedAccauntBalance }}</span>
      </div>
      <div class="HistoryDetails_row" v-if="details.risk_score">
        <span class="HistoryDetails_risk-panel">{{ details.risk_score }}</span>
      </div>
    </div>
    <div class="HistoryDetails_col HistoryDetails_col--high">
      <div class="HistoryDetails_column">
        <div class="HistoryDetails_column-title">High risk</div>
        <div class="HistoryDetails_risk-part" v-for="(risk, index) in highRiskDetails" :key="index">
          <div class="HistoryDetails_risk-row">
            <div class="HistoryDetails_risk-name">
              {{ risk.pretty_name }} <span>{{ risk.value }}%</span>
            </div>
            <div class="HistoryDetails_risk-value HistoryDetails_risk-value--high" :style="`height: ${risk.value}%`" />
          </div>
        </div>
      </div>
    </div>
    <div class="HistoryDetails_col HistoryDetails_col--medium">
      <div class="HistoryDetails_column">
        <div class="HistoryDetails_column-title">Medium risk</div>
        <div class="HistoryDetails_risk-part" v-for="(risk, index) in mediumRiskDetails" :key="index">
          <div class="HistoryDetails_risk-row">
            <div class="HistoryDetails_risk-name">
              {{ risk.pretty_name }} <span>{{ risk.value }}%</span>
            </div>
            <div class="HistoryDetails_risk-value HistoryDetails_risk-value--medium" :style="`height: ${risk.value}%`" />
          </div>
        </div>
      </div>
    </div>
    <div class="HistoryDetails_col HistoryDetails_col--low">
      <div class="HistoryDetails_column">
        <div class="HistoryDetails_column-title">Low risk</div>
        <div class="HistoryDetails_risk-part" v-for="(risk, index) in lowRiskDetails" :key="index">
          <div class="HistoryDetails_risk-row">
            <div class="HistoryDetails_risk-name">
              {{ risk.pretty_name }} <span>{{ risk.value }}%</span>
            </div>
            <div class="HistoryDetails_risk-value HistoryDetails_risk-value--low" :style="`height: ${risk.value}%`" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatScanTransactionDetails } from '../../_helpers/detailsFormatter';
import { isEmpty } from 'ramda';
import { roundingCurrency } from '@/utils/helpers';

export default {
  props: {
    order: {
      type: [Object, Array],
      required: true,
    },
  },
  computed: {
    formatedAccauntBalance() {
      if(this.order.account_balance) {
        return Object.entries(this.order.account_balance).map(([currency, currencyAmount]) => `${roundingCurrency({ currencyAmount, currency })} ${currency}`).join(', ')
      }

      return false
    },
    feeFormatted() {
      return roundingCurrency({
        currencyAmount: this.order.fee,
        currency: this.order.currency,
      })
    },  
    details() {
      return formatScanTransactionDetails(this.order);
    },
    hasRisksDetails() {
      return !isEmpty(this.details) && !isEmpty(this.details.transactionRisks);
    },
    highRiskDetails() {
      if (this.hasRisksDetails) {
        const risks = this.details.transactionRisks['high_risk'];
        return risks;
      }
      return {};
    },
    mediumRiskDetails() {
      if (this.hasRisksDetails) {
        const risks = this.details.transactionRisks['medium_risk'];
        return risks;
      }
      return {};
    },
    lowRiskDetails() {
      if (this.hasRisksDetails) {
        const risks = this.details.transactionRisks['low_risk'];
        return risks;
      }
      return {};
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';

.HistoryDetails_container {
  &--grid {
    @media only screen and (min-width: $desktop-width) {
      display: grid;
      grid-template-columns: 25% 25% 25% 25%;
    }
  }
}
.HistoryDetails_row {
  text-align: left;
  .HistoryDetails_label {
    font-size: 12px;
    line-height: 14px;
    color: #a6aebf;
    @media only screen and (min-width: $large-width) {
      font-size: 14px;
      line-height: 16px;
    }
    @media only screen and (min-width: $wide-width) {
      font-size: 16px;
      line-height: 19px;
    }
  }
  .HistoryDetails_value {
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    margin-left: 3px;
    @media only screen and (min-width: $large-width) {
      font-size: 16px;
      line-height: 18px;
    }
    &--link {
      color: #2af1f1;
      text-decoration: none;
    }
  }
  .HistoryDetails_risk-panel {
    display: inline-block;
    margin-top: 5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #2af3f3;
    padding: 5px;
    background: rgba(#2af3f3, 0.1);
    border-radius: 2px;
    @media only screen and (min-width: $large-width) {
      font-size: 14px;
      line-height: 16px;
    }
    @media only screen and (min-width: $wide-width) {
      font-size: 16px;
      line-height: 19px;
    }
  }
}

.HistoryDetails_col {
  &--medium {
    @media only screen and (min-width: $desktop-width) {
      border-left: 1px dashed rgba(#2af3f3, 0.21);
      border-right: 1px dashed rgba(#2af3f3, 0.21);
    }
  }
}

.HistoryDetails_column {
  height: 100%;
  padding: 10px;
  @media only screen and (min-width: $desktop-width) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0 10px;
  }
}

.HistoryDetails_column-title {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #2af3f3;
  padding-bottom: 12px;
  width: 100%;
  @media only screen and (min-width: $large-width) {
    font-size: 14px;
    line-height: 16px;
  }
  @media only screen and (min-width: $wide-width) {
    font-size: 16px;
    line-height: 19px;
  }
}

.HistoryDetails_risk-part {
  flex: 1;
  width: 100%;
}

.HistoryDetails_risk-row {
  @media only screen and (min-width: $desktop-width) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
  }
  height: 100%;
  .HistoryDetails_risk-name {
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    @media only screen and (min-width: $desktop-width) {
      display: flex;
      align-items: center;
      text-align: right;
      max-width: calc(100% - 10px - 47px);
    }
    @media only screen and (min-width: $large-width) {
      font-size: 12px;
      line-height: 14px;
    }
    @media only screen and (min-width: $wide-width) {
      font-size: 14px;
      line-height: 16px;
    }
    color: #ffffff;
    margin-right: 10px;
    word-break: inherit;
    span {
      font-size: 12px;
      line-height: 14px;
      color: #ffffff;
      word-break: normal;
      margin-left: 5px;
      @media only screen and (min-width: $large-width) {
        font-size: 14px;
        line-height: 16px;
      }
      @media only screen and (min-width: $wide-width) {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
  .HistoryDetails_risk-value {
    width: 47px;
    min-height: 1px;
    margin: 10px auto 0;
    @media only screen and (min-width: $desktop-width) {
      margin: 0;
    }
    &--high {
      background: #ff556d;
    }
    &--medium {
      background: #f0c854;
    }
    &--low {
      background: #5abc67;
    }
  }
}
</style>
