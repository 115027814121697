<template>
  <div class="w-full">
    <ComponentWrapper
      title="History"
      withSubTitle
    >
      <template #subTitle>
        <Button text="Download XLS"
          brightBtn
          @click="generateXLSX"
          class="History_download px-[10px]"
        >
          <!-- <template #icon>
            <iconVerified :height="12" :width="12" />
          </template> -->
        </Button>
      </template>
      <div class="px-[10px] py-[20px] md:px-[20px]"
           slot="content">
        <HistoryTable :orders="ordersHistory" />
        <div
          class="flex justify-center md:justify-between items-center mt-[15px]"
          v-if="ordersHistoryPageCount > 0"
        >
          <div class="text-[18px] leading-[18px] text-blue-700 dark:text-white hidden xl:block">Page {{ historyFilters.page }} of {{ ordersHistoryPageCount }}</div>
          <v-pagination
            class="history-pagination mt-[10px]"
            :total-visible="totalVisible"
            :value="historyFilters.page"
            :length="ordersHistoryPageCount"
            @input="handlePageChange"
          ></v-pagination>
        </div>
      </div>
    </ComponentWrapper>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import ComponentWrapper from "@/components/_common/ComponentWrapper/ComponentWrapper"
import HistoryTable from "./HistoryTable/HistoryTable"
import { saveAs } from "file-saver"
import * as XLSX from "xlsx"
import { HISTORY_TYPES_FILTER } from "@/constants"
import Button from '@/components/_common/FormElements/Button/Button'
import { iconVerified } from '@/assets/icons/common/';

export default {
  components: {
    HistoryTable,
    ComponentWrapper,
    Button,
    iconVerified,
  },

  computed: {
    ...mapState( "user", [ "ordersHistory", "historyFilters", "ordersHistoryPageCount", "ordersHistoryForXLSX" ] ),
    ...mapGetters( "user", [ "getOrdersHistoryAllForXLSX", "getOrderHistoryForXLSX" ] ),
    totalVisible() {
      return this.historyFilters.page > 3 ? 7 : 5
    },
  },

  methods: {
    ...mapActions( "user", [ "changeHistoryPage", "apiGetOrdersHistory", "apiGetOrdersHistoryForXLSX" ] ),
    handlePageChange( page ) {
      this.changeHistoryPage( page )
      this.apiGetOrdersHistory()
    },

    generateXLSXNoStyle() {
      // Stylization does not work, most likely only available in the paid version
      // STEP 1: Create a new workbook
      const wb = XLSX.utils.book_new();

      // STEP 2: Create data rows and styles
      let row = [
        { v: "Courier: 24", t: "s", s: { font: { name: "Courier", sz: 24 } } },
        { v: "bold & color", t: "s", s: { font: { bold: true, color: { rgb: "FF0000" } } } },
        { v: "fill: color", t: "s", s: { fill: { fgColor: { rgb: "E9E9E9" } } } },
        { v: "line\nbreak", t: "s", s: { alignment: { wrapText: true } } },
      ];

      // STEP 3: Create worksheet with rows; Add worksheet to workbook
      const ws = XLSX.utils.aoa_to_sheet([row]);
      XLSX.utils.book_append_sheet(wb, ws, "readme demo");

      // STEP 4: Write Excel file to browser
      XLSX.writeFile(wb, "xlsx-js-style-demo.xlsx");
    },

    getWsCols(data) {
      let objectMaxLength = [];
      for (let i = 0; i < data.length; i++) {
        let value = Object.values(data[i]);
        for (let j = 0; j < value.length; j++) {
          objectMaxLength[j] = objectMaxLength[j] >= value[j].length ? objectMaxLength[j] : value[j].length;
        }
      }
      const wscols = objectMaxLength.map(item=>{
        let result = item || 14
        result = result < 10 ? 10 : result + 4
        return {width: result}
      })
      return wscols
    },

    async generateXLSX() {
      await this.apiGetOrdersHistoryForXLSX()

      const wb = XLSX.utils.book_new()

      wb.Props = {
        Title: "KAURI",
        Subject: "KAURI",
        Author: "KAURI",
        CreatedDate: new Date(),
      }

      wb.SheetNames.push( "ALL" )
      const ws_data = this.getOrdersHistoryAllForXLSX
      const ws = XLSX.utils.json_to_sheet( ws_data )
      ws['!autofilter'] = { ref:"K1" }
      ws["!cols"] = this.getWsCols(ws_data)
      wb.Sheets[ "ALL" ] = ws

      const arrayTypes = HISTORY_TYPES_FILTER.filter( item => item.key )
      arrayTypes.forEach( item => {
        wb.SheetNames.push( item.key )
        const ws_dataType = this.getOrderHistoryForXLSX( item.key )
        const wsType = XLSX.utils.json_to_sheet( ws_dataType )
        wsType['!autofilter'] = { ref:"K1" }
        wsType["!cols"] = this.getWsCols(ws_dataType)
        wb.Sheets[ item.key ] = wsType
      } )

      const wbout = XLSX.write( wb, { bookType: "xlsx", type: "binary" } )

      function s2ab( s ) {
        const buf = new ArrayBuffer( s.length )
        const view = new Uint8Array( buf )
        for ( let i = 0; i < s.length; i++ ) view[ i ] = s.charCodeAt( i ) & 0xFF
        return buf
      }

      saveAs( new Blob( [ s2ab( wbout ) ], { type: "application/octet-stream" } ), "History-table.xlsx" )
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.history-pagination {
  .v-pagination {
    li {
      .v-pagination__item {
        background: transparent !important;
        color: #0F1E30;
        box-shadow: none;
        font-size: 18px;
        outline: none;
        min-width: 20px;

        &--active {
          background-color: transparent !important;
          color: #2af3f3 !important;
        }
      }

      .v-pagination__navigation {
        outline: none;
        width: 38px;
        height: 38px;
        background-color: transparent !important;
        background: rgba(42, 243, 243, 0.1) !important;
        box-shadow: none;
        transition: 0.1s all ease;

        &:hover {
          background: #2af3f3 !important;
          box-shadow: 0px 0px 10px rgba(42, 243, 243, 0.55);

          .v-icon {
            color: #2d4d70;
          }
        }
      }
    }
  }
  .v-pagination__more {
    color: #0F1E30;
  }
}
body {
  &.dark {
    .history-pagination {
      .v-pagination {
        li {
          .v-pagination__item {
            color: #fff;
          }
        }
      }
    }
    .v-pagination__more {
      color: #fff;
    }
  }
}
</style>
