<template>
    <div class="sepa-details-container">
      <div class="text-left break-all">
        <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Internal id:</span>
        <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ order.internal_id }}</span>
      </div>
      <div class="text-left break-all">
        <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Beneficiary:</span>
        <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ details.beneficiary }}</span>
      </div>
      <div class="text-left break-all">
        <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Account number:</span>
        <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ details.account_number }}</span>
      </div>
      <div class="text-left break-all" v-if="details.payment_details">
        <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Details:</span>
        <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ details.payment_details }}</span>
      </div>
      <div class="text-left break-all">
        <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Fee:</span>
        <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ feeFormatted }} {{ order.currency }}</span>
      </div>
      <div class="text-left break-all" v-if="details.comment">
        <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Comment:</span>
        <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ details.comment }}</span>
      </div>
      <div
        class="text-left break-all"
        v-if="formatedAccauntBalance"
      >
        <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Account balance:</span>
        <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ formatedAccauntBalance }}</span>
      </div>
      <button
        class="m-btn mr-auto block"
        @click="downloadPdf"
      >
        Download receipt
      </button>
      <DetailsSwiftSepaPDF
        v-show="isShowPdf"
        ref="pdfComponent"
        :order="order"
      />
    </div>
</template>

<script>
import { isEmpty } from 'ramda';
import { roundingCurrency } from '@/utils/helpers';
import DetailsSwiftSepaPDF from "./DetailsSwiftSepaPDF"
import { jsPDF } from "jspdf"

export default {
  props: {
    order: {
      type: [Object, Array],
      required: true,
    },
  },
  components: {
    DetailsSwiftSepaPDF,
  },

  data: () => ( {
    isShowPdf: false
  } ),
  
  computed: {
    details() {
      if (!isEmpty(this.order) && !isEmpty(this.order.details)) {
        return this.order.details;
      }
      return {};
    },
    feeFormatted() {
      return roundingCurrency({
        currencyAmount: this.details.fee,
        currency: this.order.currency,
      })
    },
    formatedAccauntBalance() {
      if(this.order.account_balance) {
        return Object.entries(this.order.account_balance).map(([currency, currencyAmount]) => `${roundingCurrency({ currencyAmount, currency })} ${currency}`).join(', ')
      }

      return false
    },
  },
  methods: {
    downloadPdf() {
      this.isShowPdf = true
      const HTML = this.$refs.pdfComponent.$el

      let pdf = new jsPDF( {
        orientation: "portrait",
        format: "a4",
        unit: "px"
      } )

      // $('#pdf').css({
      //   width: pdf.internal.pageSize.width,
      //   height: pdf.internal.pageSize.height * $('.pdf-page').length - 1
      // }).attr('data-pdf-page-height', pdf.internal.pageSize.height)
      // $('.pdf-page').css({
      //     height: pdf.internal.pageSize.height,
      // })
      // $('#pdf').css({ display: 'block' })
      pdf.html( HTML, {
        callback: ( pdf ) => {
          pdf.save( "receipt.pdf" )
          this.isShowPdf = false
        }
      } )
    }
  }
};
</script>

<style lang="scss">
.m-btn {
  margin-top: 10px;
  padding: 8px 16px;
  background: #2AF3F3;
  box-shadow: 0px 0px 10px rgba(42, 243, 243, 0.55);
  border-radius: 6px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #2D4D70;

  @media (any-hover: hover) {
    &:hover {
      background: #a5ffff;
      box-shadow: 0px 0px 10px rgb(42 243 243 / 55%);
    }
  }
}
</style>
