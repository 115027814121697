<template>
  <ComponentWrapper title="Wallet">
    <div slot="filterWallet">
      <WalletFilter
        @onChooseWalletItemsFilter="currentNameWalletItem = $event"
      >
      </WalletFilter>
    </div>
    <div slot="content">
      <div
        class="Wallet_footer md:hidden"
        v-if="false"
      >
        <div class="wallet-footer-wrapper">
          Balance: <span class="text-[18px] leading-[21px] text-blue-300 dark:text-blue-500">{{ computedTotalBalance }}</span>
          <Select
            :items="currenciesToConvert"
            type="inputSelect"
            :value="totalAmountCurrency"
            name="currency"
            @change="updateSelectCurrency(arguments[0], 'currency')"
          />
        </div>
      </div>
      <div
        class="Wallet_footer Wallet_footer--type-2 md:hidden"
        v-if="true"
      >
        <div class="py-[12px] bg-blue-700/[0.04] dark:bg-blue-500/[0.06] rounded-[2px] flex items-center justify-center">
          <div class="wallet-total-type-2 wallet-total-type-2--top order-1">
            <span class="text-blue-300 dark:text-white">
              <SvgIcon
                icon="iconCoins"
              ></SvgIcon>
            </span>
            <span class="text-[18px] leading-[21px] text-blue-300 dark:text-blue-500">{{ computedTotalBalance }}</span>
            <CurrencyFilter
              currencyFilterStyles="flex"
              :filterHasAllValue="false"
              emitField="currency_to_spend"
              :filterHasLabel="false"
              :defaulCurrency="totalAmountCurrency"
              :defaulCurrencyList="defaultCurrencyList"
              @change="updateSelectCurrency(arguments[0], 'currency')"
            />
          </div>
          <div class="text-blue-700 dark:text-white text-center text-[12px] leading-[14px] tracking-wider font-normal order-0 mr-[10px]">Total balance</div>
        </div>
      </div>
        <div class="wallet-wrapper overflow-auto px-[10px] md:pr-[16px] md:pl-[21px]">
          <div v-if="isBalancesLoaded && sortedGetterCryptoAndFiatBalance && sortedGetterCryptoAndFiatBalance.length > 0">
            <template v-for="(item, key) in getFilterWalletsItem">
              <WalletItem
                :key="key"
                :item="item"
                :anotherCurrency="totalAmountCurrency"
              />
            </template>
          </div>
          <div
            class="Wallet_skeleton"
            v-if="isBalancesPending"
          >
            <v-skeleton-loader
              key="skeleton"
              transition-group="fade-transition"
              type="table-cell@4"
            />
          </div>
        </div>
      <Button
        v-if="isMobileView"
        class="flex justify-center items-center btn btn-small btn-bright w-full min-h-[30px] mt-[20px]"
        :text="`Show ${ showAllItems ? 'less' : 'more' }`"
        fullWidth
        @click="showAllItemsOnMobile"
      >
        <img src="@/assets/icons/icon-show-more.svg" class="mr-[10px]">
      </Button>
      <div
        class="Wallet_footer hidden md:block"
        v-if="false"
      >
        <div class="wallet-footer-wrapper">
          Balance: <span class="text-[18px] leading-[21px] text-blue-300 dark:text-blue-500">{{ computedTotalBalance }}</span>
          <Select
            :items="currenciesToConvert"
            type="inputSelect"
            :value="totalAmountCurrency"
            name="currency"
            @change="updateSelectCurrency(arguments[0], 'currency')"
          />
        </div>
      </div>
      <div
        class="Wallet_footer Wallet_footer--type-2 hidden md:block"
        v-if="true"
      >
        <div class="py-[12px] bg-blue-700/[0.04] dark:bg-blue-500/[0.06] rounded-[2px]">
          <div class="wallet-total-type-2">
            <span class="text-blue-300 dark:text-white">
              <SvgIcon
                icon="iconCoins"
              ></SvgIcon>
            </span>
            <span class="text-[18px] leading-[21px] text-blue-300 dark:text-blue-500">{{ computedTotalBalance }}</span>
            <CurrencyFilter
              currencyFilterStyles="flex flex-wrap"
              :filterHasAllValue="false"
              emitField="currency_to_spend"
              :filterHasLabel="false"
              :defaulCurrency="totalAmountCurrency"
              :defaulCurrencyList="defaultCurrencyList"
              @change="updateSelectCurrency(arguments[0], 'currency')"
            />
          </div>
          <div class="text-blue-700 dark:text-white text-center text-[12px] leading-[14px] tracking-wider font-normal">Total balance</div>
        </div>
      </div>
    </div>
  </ComponentWrapper>
</template>

<script>
import { isEmpty } from 'ramda';
import { mapState, mapGetters } from 'vuex';
import { getTotalBalanceByCurrency } from '@/components/_common/Wallet/_helpers/userBalances';
import ComponentWrapper from '@/components/_common/ComponentWrapper/ComponentWrapper';
import WalletItem from './WalletItem/WalletItem';
import Select from '@/components/_common/FormElements/Select/Select';
import CurrencyFilter from "@/components/_common/ExtraFilters/CurrencyFilter/CurrencyFilter.vue";
import SvgIcon from "@/components/_common/SvgIcon";
import WalletFilter from "@/components/_common/WalletFilter/WalletFilter";
import Button from '@/components/_common/FormElements/Button/Button';
import { isCrypto } from '@/store/balance/_helpers/currencyTypeHelper';

export default {
  components: {
    WalletFilter,
    CurrencyFilter,
    ComponentWrapper,
    WalletItem,
    Select,
    SvgIcon,
    Button
  },
  data: () => ({
    totalAmountCurrency: "EUR",
    currenciesToConvert: [ "BTC", "USDT", "EUR" ],
    filteredWalletsItems: [],
    currentNameWalletItem: 'ShowAll',
    showAllItems: false,
  }),
  computed: {
    ...mapGetters( "balance", [ "isBalancesPending", "isBalancesLoaded", "sortedGetterCryptoAndFiatBalance" ] ),
    ...mapState( "balance", [ "balance" ] ),
    computedTotalBalance() {
      return getTotalBalanceByCurrency(this.totalAmountCurrency);
    },
    defaultCurrencyList() {
      return this.currenciesToConvert.map( item => ({ key: item, value: item }))
    },
    getFilterWalletsItem() {
      const windowInnerWidth = window.innerWidth;

      if(windowInnerWidth < 768) {
        if (!this.showAllItems) {
          return this.sortedGetterCryptoAndFiatBalance.slice(0, 3)
            .filter(itemWallet => {
              const { currency, ...restitemWallet } = itemWallet;
              const zeroBalance = (restitemWallet[this.totalAmountCurrency] && restitemWallet[this.totalAmountCurrency].total) || 0;
              if (this.currentNameWalletItem === 'ShowAll') {
                return itemWallet
              }

              if (this.currentNameWalletItem === 'ShowOnlyFiat' && !isCrypto(currency) && itemWallet.currencyType === 'fiat') {
                return itemWallet[currency]
              }

              if (this.currentNameWalletItem === 'ShowOnlyCrypto' && isCrypto(currency) && itemWallet.currencyType === 'crypto') {
                return itemWallet[currency]
              }

              if (this.currentNameWalletItem === 'HideCurrencyZeroBalance' && zeroBalance !== 0) {
                return zeroBalance
              }
            })
        } else if (this.showAllItems) {
          return this.sortedGetterCryptoAndFiatBalance.filter(itemWallet => {
            const { currency, ...restitemWallet } = itemWallet;
            const zeroBalance = (restitemWallet[this.totalAmountCurrency] && restitemWallet[this.totalAmountCurrency].total) || 0;
            if (this.currentNameWalletItem === 'ShowAll') {
              return itemWallet
            }

            if (this.currentNameWalletItem === 'ShowOnlyFiat' && !isCrypto(currency) && itemWallet.currencyType === 'fiat') {
              return itemWallet[currency]
            }

            if (this.currentNameWalletItem === 'ShowOnlyCrypto' && isCrypto(currency) && itemWallet.currencyType === 'crypto') {
              return itemWallet[currency]
            }

            if (this.currentNameWalletItem === 'HideCurrencyZeroBalance' && zeroBalance !== 0) {
              return zeroBalance
            }
          })
        }
      }

      return this.sortedGetterCryptoAndFiatBalance.filter(itemWallet => {
        const { currency, ...restitemWallet } = itemWallet;
        const zeroBalance = (restitemWallet[this.totalAmountCurrency] && restitemWallet[this.totalAmountCurrency].total) || 0;
        if (this.currentNameWalletItem === 'ShowAll') {
          return itemWallet
        }

        if (this.currentNameWalletItem === 'ShowOnlyFiat' && !isCrypto(currency) && itemWallet.currencyType === 'fiat') {
          return itemWallet[currency]
        }

        if (this.currentNameWalletItem === 'ShowOnlyCrypto' && isCrypto(currency) && itemWallet.currencyType === 'crypto') {
          return itemWallet[currency]
        }

        if (this.currentNameWalletItem === 'HideCurrencyZeroBalance' && zeroBalance !== 0) {
          return zeroBalance
        }
      })
    },
    isMobileView() {
      const windowInnerWidth = window.innerWidth;

      if(windowInnerWidth < 768) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    showAllItemsOnMobile() {
      this.showAllItems = !this.showAllItems;
    },
    updateSelectCurrency( { value } ) {
      this.totalAmountCurrency = value;
    }
  },
  mounted() {
    this.isMobileView;
  }
};
</script>
<style lang="scss">

@import "./Wallet.scss";

.Wallet_wrapper {
  .Wallet_skeleton {
    padding: 30px 0 5px;

    .v-skeleton-loader {
      .v-skeleton-loader__table-cell {
        width: 100%;
        margin-bottom: 15px;
        height: 75px;

        &:last-of-type {
          margin-bottom: 0;
        }

        .v-skeleton-loader__text {
          height: 75px;
        }
      }
    }
  }
}

.wallet-footer-wrapper {
  position: relative;
  display: inline-block;

  .select-wrapper.select-wrapper--inputSelect {
    right: 0;
    top: 50%;
    transform: translate(95%, -51%);
    bottom: auto;
  }
}

.Wallet_footer--type-2 {
  padding: 14px 10px 10px;

  @media only screen and (min-width: $tablet-width) {
    padding: 9px 16px 16px 20px;
  }
}

.wallet-total-type-2 {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(3, auto);
  grid-column-gap: 6px;
  margin: 0 0 10px;

  &--top {
    margin: 0;
  }

  .select-wrapper--inputSelect {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .Select_root.v-select.v-text-field--solo > .v-input__control {
    min-height: 30px;
  }

  .ExtraFilter_InputArea {
    height: 35px;
    background: none;
  }
}
</style>
