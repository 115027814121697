<template>
  <div class="exchange-details-container">
    <div class="text-left">
      <div>
        <span class="HistoryDetails_greenColor text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] odd:mr-[5px] font-medium 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Internal id:</span>
        <span class="text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] odd:mr-[5px] font-medium 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ order.internal_id }}</span>
      </div>
      <div>
        <span class="HistoryDetails_greenColor text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] odd:mr-[5px] font-medium 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Buy:</span>
        <span class="text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] odd:mr-[5px] font-medium 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ currencyToGetFormatted }} {{ details.currencyToGet }}</span>
      </div>
      <div>
        <span class="HistoryDetails_redColor text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] odd:mr-[5px] font-medium 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Sell:</span>
        <span class="text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] odd:mr-[5px] font-medium 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ currencyToSpendFormatted }} {{ details.currencyToSpend }}</span>
      </div>
      <div>
        <span class="HistoryDetails_blueColor text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] odd:mr-[5px] font-medium 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Rate:</span>
        <span class="text-blue-700 dark:text-white text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] odd:mr-[5px] font-medium 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">{{ details.price }}</span>
      </div>
      <div>
        <span class="HistoryDetails_purpleColor text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] odd:mr-[5px] font-medium 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Fee:</span>
        <span class="text-blue-700 dark:text-white text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] odd:mr-[5px] font-medium 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">{{ feeFormatted }} {{ details.currencyToSpend }}</span>
      </div>
      <div v-if="formatedAccauntBalance">
        <span class="HistoryDetails_purpleColor text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] odd:mr-[5px] font-medium 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Account balance:</span>
        <span class="text-blue-700 dark:text-white text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] odd:mr-[5px] font-medium 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">{{ formatedAccauntBalance }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { formatExchangeDetails } from '../_helpers/detailsFormatter';
import { roundingCurrency } from '@/utils/helpers';

export default {
  props: {
    order: {
      type: [Object, Array],
      required: true,
    },
  },
  computed: {
    formatedAccauntBalance() {
      if(this.order.account_balance) {
        return Object.entries(this.order.account_balance).map(([currency, currencyAmount]) => `${roundingCurrency({ currencyAmount, currency })} ${currency}`).join(', ')
      }

      return false
    },
    details() {
      return formatExchangeDetails(this.order)
    },
    feeFormatted() {
      return roundingCurrency({
        currencyAmount: this.details.fee,
        currency: this.details.currencyToSpend,
      })
    },
    currencyToGetFormatted() {
      return roundingCurrency({
        currencyAmount: this.details.currency_to_get_amount,
        currency: this.details.currencyToGet,
      })
    },
    currencyToSpendFormatted() {
      return roundingCurrency({
        currencyAmount: this.details.currency_to_spend_amount,
        currency: this.details.currencyToSpend,
      })
    },
  },
};
</script>