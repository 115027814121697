<template>
  <div class="min-h-[670px]">
    <v-skeleton-loader
      class="HistoryTable_filter-skeleton"
      transition-group="fade-transition"
      v-if="_ordersHistoryPending"
      type="table-cell@1"
    />
    <div class="HistoryTable_filters" v-if="!_ordersHistoryPending">
      <Select
        class="HistoryTable_filter"
        :items="filterTypes"
        :value="historyFilters.order_type"
        name="order_type"
        @change="onFilterChange"
        label="type"
        placeholder="type"
      />
      <div
        class="HistoryTable_filter relative"
        v-click-outside="handleOutsidePickerClick"
      >
        <TextFieldInput
          customStylesTextFieldInput="date-picker-input"
          name="date"
          placeholder="Date"
          label="Date"
          disabled
          hideDetails
          dropdownArrow
          @click="handleActivateDatePicker"
          :dropdownActive="datePickerActive"
          :value="computedDateInputValue"
        />
        <DatePicker
          v-if="datePickerActive"
          :datesValue="computeDatePickerValue"
          :selectValue="historyDateFilter.date_type"
          dateRange
          hideSelect
          @updateDate="handleUpdateDateValue"
          @submit="handleApplyDateFilter"
          @clear="handleClearDateFilter"
        />
      </div>
      <CurrencyFilter
        :defaulCurrency="historyFilters.currency"
        :defaulCurrencyList="currenciesFilter"
        currencyFilterStyles="cursor-pointer flex items-center justify-between h-[46px] border border-[#E8ECF3] dark:border-none bg-white dark:bg-white/[0.04] items-center rounded-[4px] px-[12px]"
        class="HistoryTable_filter"
        @change="onFilterChange"
      />
      <StatusFilter
        styleStatusFilter="cursor-pointer flex items-center justify-between h-[46px] border border-[#E8ECF3] dark:border-none bg-white dark:bg-white/[0.04] items-center rounded-[4px] px-[12px]"
        class="HistoryTable_filter"
        :defaulStatus="historyFilters.order_status"
        :defaulStatusList="filterStatuses"
        @change="onFilterChange"
      />
    </div>
    <v-skeleton-loader key="skeleton" transition-group="fade-transition" v-if="_ordersHistoryPending" type="table-cell@10" />
    <div v-if="!_ordersHistoryPending && hasOrders">
      <HistoryRow
        v-for="order in orders"
        :key="order.external_id"
        :order="order"
        @cancelOrder="handleCancelOrder"
      ></HistoryRow>
    </div>
    <EmptyState v-if="!hasOrders && !_ordersHistoryPending" />
  </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapActions } from 'vuex'
import { isEmpty } from 'ramda'
import Select from '@/components/_common/FormElements/Select/Select'
import DatePicker from '@/components/_common/FormElements/DatePicker/DatePicker'
import TextFieldInput from '@/components/_common/FormElements/TextFieldInput/TextFieldInput'
import { HISTORY_TYPES_FILTER, HISTORY_STATUSES_FILTER } from '@/constants'
import { prepareDateFilterTimestampForRequest } from '@/views/Application/Account/Providers/History/HistoryTable/_helpers/dateTimeFormatter'
import HistoryRow from './HistoryRow/HistoryRow'
import EmptyState from './EmptyState'
import CurrencyFilter from '@/components/_common/ExtraFilters/CurrencyFilter/CurrencyFilter'
import StatusFilter from '@/components/_common/ExtraFilters/StatusFilter/StatusFilter'
import ClickOutside from 'vue-click-outside'

export default {
  directives: {
    ClickOutside,
  },
  components: {
    HistoryRow,
    EmptyState,
    Select,
    DatePicker,
    TextFieldInput,
    CurrencyFilter,
    StatusFilter,
  },
  props: {
    orders: {
      type: [ Array, Object ],
      required: true,
    },
  },
  data() {
    return {
      filterTypes: HISTORY_TYPES_FILTER,
      filterStatuses: HISTORY_STATUSES_FILTER,
      datePickerActive: false,
    }
  },
  computed: {
    ...mapState('user',
      [
        'historyFilters',
        '_ordersHistoryPending',
        '_ordersHistoryLoaded',
        'historyDateFilter'
      ]),
    ...mapState('exchange', ['currencies']),
    hasOrders() {
      return !isEmpty( this.orders )
    },
    currenciesFilter() {
      if ( this.currencies.length ) {
        const currenciesArray =
          this.currencies.length &&
          this.currencies.reduce( ( acc, curr ) => {
            acc.push( {
              key: curr,
              value: curr,
            } )
            return acc
          }, [] )
        return currenciesArray
      }
      return []
    },
    computeDatePickerValue() {
      const { from_timestamp, till_timestamp } = this.historyDateFilter
      if ( !!from_timestamp && !!till_timestamp ) {
        const dateFrom = moment( from_timestamp, 'X' ).format( 'YYYY-MM-DD' )
        const dateTo = moment( till_timestamp, 'X' ).format( 'YYYY-MM-DD' )
        return [ dateFrom, dateTo ]
      }
      return []
    },
    computedDateInputValue() {
      const { from_timestamp, till_timestamp } = this.historyDateFilter
      if ( !!from_timestamp && !!till_timestamp ) {
        const dateFrom = moment( from_timestamp, 'X' ).format( 'YYYY.MM.DD' )
        const dateTo = moment( till_timestamp, 'X' ).format( 'YYYY.MM.DD' )
        return `${ dateFrom } - ${ dateTo }`
      }
      return ''
    },
  },
  methods: {
    ...mapActions('user',
      [
        'populateHistoryFilter',
        'apiGetOrdersHistory',
        'apiCancelLimitExchange',
        'populateHistoryDateFilter'
      ]),
    handleOutsidePickerClick() {
      if ( this.datePickerActive ) {
        this.datePickerActive = false
      }
    },
    handleActivateDatePicker() {
      this.datePickerActive = !this.datePickerActive;
    },
    onFilterChange( payload ) {
      this.populateHistoryFilter( payload )
      this.apiGetOrdersHistory( { filter: true } )
    },
    handleCancelOrder( order_id ) {
      this.apiCancelLimitExchange( order_id )
    },
    handleUpdateDateValue( payload ) {
      const modifiedPayload = prepareDateFilterTimestampForRequest( payload )
      modifiedPayload.forEach( ( { field, value } ) => {
        this.populateHistoryDateFilter( { field, value } )
      } )
    },
    handleClearDateFilter() {
      this.datePickerActive = false
      this.populateHistoryDateFilter( { field: 'from_timestamp', value: '' } )
      this.populateHistoryDateFilter( { field: 'till_timestamp', value: '' } )
      this.apiGetOrdersHistory()
    },
    handleApplyDateFilter() {
      this.datePickerActive = false
      this.apiGetOrdersHistory()
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
@import './HistoryDetails/HistoryDetails.scss';

.HistoryTable_heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  row-gap: 8px;
  padding: 0 10px;

  @media only screen and (min-width: $tablet-width) {
    display: none;
  }

  .HistoryTable_head {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #2af3f3;
    opacity: 0.89;
    width: 25%;
    text-align: center;

    &:first-of-type {
      text-align: left;
    }
    &:nth-of-type(4) {
      text-align: right;
      padding-right: 14px;
    }
  }
}

.HistoryTable_filters {
  margin-bottom: 16px;

  @media only screen and (min-width: $tablet-width) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .HistoryTable_filter {
    margin-bottom: 10px;

    @media only screen and (min-width: $tablet-width) {
      flex: 0 1 calc(25% - 6px);
      margin: 0;
    }
  }

  // временно, исправить после согласования макета
  .text-field-input-root {
    cursor: pointer;

    .v-label {
      font-size: 16px;
      line-height: 18px;
      color: #fff;
    }
  }
}

.v-skeleton-loader {
  .v-skeleton-loader__table-cell {
    width: 100%;
    margin-bottom: 8px;

    .v-skeleton-loader__text {
      height: 50px;
    }
  }
}

.HistoryTable_filter-skeleton.v-skeleton-loader {
  .v-skeleton-loader__table-cell {
    height: 69px;

    .v-skeleton-loader__text {
      height: 69px;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
{
  opacity: 0;
}
</style>
