import { isEmpty } from 'ramda';

const formattedOrderStatuses = {
  CLOSED: 'completed',
  WAITING_FOR_PRICE: 'waiting',
  WAITING_FOR_CONFIRMATION: 'waiting',
  CONFIRMED_BY_OPERATOR: 'waiting',
  WAITING_FOR_OPERATOR_CONFIRMATION: 'waiting',
  PAYMENT_IN_PROGRESS: 'waiting',
  EXPIRED: 'expired',
  CANCELLED_BY_OPERATOR: 'waiting',
};

export const formatHistoryOrderStatusToView = order => {
  if (isEmpty(order)) return '-';
  const { status } = order;
  const formattedStatus = (status && status.toLowerCase()) || status;
  const orderStatusToView = formattedOrderStatuses[status] || formattedStatus;
  return orderStatusToView;
};
