<template>
  <div class="absolute flex flex-col h-[631px] bg-[#fff] p-[20px] !text-[#000] text-[8px] w-[446.46px]">
    <header class="flex justify-between items-end">
      <img
        src="@/assets/img/dark_logo_small.png"
        alt="logo"
        class="object-contain w-[40px] h-[40px]"
      />
      <span class="font-light !text-[13px]">Transaction details</span>
    </header>
    <section class="mb-auto">
      <dl class="my-10 py-10 border-y-[1px] border-[#000]">
        <div
          v-for="(item, i) in list1"
          :key="i"
          class="mb-6 last-of-type:mb-0 grid grid-cols-2 gap-x-10 items-center"
        >
          <dt class="text-right">{{ item.dt }}</dt>
          <dd class="text-left">{{ item.dd }}</dd>
        </div>
      </dl>
      <dl>
        <div
          v-for="(item, i) in list2"
          :key="i"
          class="mb-6 last-of-type:mb-0 grid grid-cols-2 gap-x-10 items-center"
        >
          <dt class="text-right">{{ item.dt }}</dt>
          <dd class="text-left">{{ item.dd }}</dd>
        </div>
      </dl>
    </section>
    <div class="grid grid-cols-[1fr_auto] items-center">
      <span class="pl-40 text-center">Page 1 of 1</span>
      <span>{{ formattedData }}</span>
    </div>
  </div>
</template>

<script>
import { roundingCurrency } from "@/utils/helpers";

export default {
  name: "DetailsSwiftSepaPDF",

  props: {
    order: {
      type: Object,
      required: true
    }
  },

  computed: {
    list1() {
      return [
        {
          dt: "Client:",
          dd: "KAURIFINANCE OU"
        }
      ];
    },

    list2() {
      return [
        {
          dt: "Transaction number:",
          dd: this.order.internal_id ?? ""
        },
        {
          dt: "Transaction direction:",
          dd: "Outgoing"
        },
        {
          dt: "Transaction date:",
          dd: this.formattedData
        },
        {
          dt: "Beneficiary/Sender:",
          dd: this.order?.details?.beneficiary ?? ""
        },
        {
          dt: "Account number:",
          dd: this.order?.details?.account_number ?? ""
        },
        {
          dt: "Details:",
          dd: this.order?.details?.payment_details ?? ""
        },
        {
          dt: `Amount(${ this.order.currency }):`,
          dd: `${ this.formattedAmount } ${ this.order.currency }`
        }
      ];
    },

    formattedData() {
      const data = this.order?.dt?.slice( 0, 10 )
        .split( "-" );
      return `${ data[ 2 ] }.${ data[ 1 ] }.${ data[ 0 ] }` ?? "";
    },

    formattedAmount() {
      const amount = String( this.info?.amount )
        .includes( "-" )
        ? String( this.info?.amount )
          .split( "-" )[ 1 ]
        : this.info?.amount;

      return roundingCurrency( { currencyAmount: amount, currency: this.info?.currency } );
    }
  }
};
</script>
