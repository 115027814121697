import moment from 'moment';
import { isEmpty } from 'ramda';

const DATE_TIME_HISTORY_VIEW = 'DD.MM.YY HH:mm';

export const formatHistoryDateTimeToView = order => {
  if (isEmpty(order) || order.dt === 'None') return '-----';
  const { dt } = order;
  return moment(dt).format(DATE_TIME_HISTORY_VIEW);
};
