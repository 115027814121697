<template>
  <section class="min-h-full">
    <div class="h-full">
      <AppSection title="Dashboard">
        <div slot="content">
          <div class="dashboard-wallet-wrapper mb-20">
            <Wallet />
            <Exchange />
          </div>
          <div class="xl:flex">
            <History />
          </div>
        </div>
      </AppSection>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AppSection from '@/components/_common/AppSection/AppSection';
import Wallet from '@/components/_common/Wallet/Wallet';
import Exchange from '@/components/exchange/Exchange';
import History from '@/components/History/History';

export default {
  components: {
    AppSection,
    Wallet,
    Exchange,
    History,
  },
  computed: {
    ...mapState('user', ['userInfo']),
  },
  methods: {
    ...mapActions('user', ['apiGetOrdersHistory', 'logoutAction']),
  },
  mounted() {
    // TODO: rewrite logout on token fail
    this.apiGetOrdersHistory().catch(err => {
      if (err.details === 'Authentication credentials were not provided.') {
        // this.$router.push({ name: 'login' });
        this.logoutAction()
      }
    });
  },
};
</script>
<style scoped lang="scss">
  .dashboard-wallet-wrapper {
    @media screen and (min-width: 1170px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }

    @media screen and (min-width: 1440px) {
      grid-template-columns: 1fr 441px;
    }
  }
</style>