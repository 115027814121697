<template>
  <div
    class="mb-[8px] last-of-type:mb-0"
    :data-order-type="order.order_type"
  >
    <div
      class="history-row-wrapper"
      :class="{ 'active-row': isDetailsShown }"
    >
      <div
        class="history-row cursor-pointer text-left rounded-[4px] px-[10px] py-[13px] md:px-[13px] md:py-[14px] bg-white shadow-history-row-shadow dark:bg-[#213b57] dark:shadow-component-wrapper-root "
        :class="`history-row--${ computedHistoryStatus }`"
        @click="toggleDetails"
      >
        <div class="history-row-col history-row-col--type one-sell">
        <span
          class="round-arrow-btn shrink-0"
          :class="{
            'rotate-180': !isDetailsShown,
          }"
        >
          <SvgIcon class="block" icon="iconChevron" />
        </span>
          <span v-html="computedOrderType" class="text-[12px] 2xl:text-[14px]"></span>
        </div>
        <div class="history-row-col two-sell">{{ computedHistoryDate }}</div>
        <div
          class="history-row-col history-row-col--currency three-sell"
          v-if="order.order_type == 'EXCHANGE'"
        >
          <span class="HistoryDetails_redColor text-blue-700 block md:inline-block">{{ currencyToSpend }}</span>
          <span class="hidden mx-[4px] text-[13px] font-bold md:block">→</span>
          <span class="HistoryDetails_greenColor">{{ currencyToGet }}</span>
        </div>
        <div class="history-row-col three-sell" v-else>
          <div :class="{
          'HistoryDetails_redColor': isCurrencyRedColor,
          'HistoryDetails_greenColor': isCurrencyGreenColor,
        }">
            {{ computedAmount }} {{ order.currency }}
          </div>
        </div>
        <div class="history-row-col four-sell history-row-col--status">
          <div class="history-row-status whitespace-nowrap align-top ml-auto lowercase inline-block text-blue-500/[0.1] w-[80px] text-[12px] leading-[14px] text-center px-[4px] py-[4px] rounded-[2px] md:py-[4px] md:px-[6px] 2xl:text-[14px] 2xl:leading-[16px] w-[90px]" :class="`history-row-status--${ computedHistoryStatus }`">
            {{ computedHistoryStatus }}
          </div>
          <span
            class="round-arrow-btn-cancel"
            v-if="isCancelAvailable"
            @click="handleCancelOrder"
          >
          <SvgIcon
            class="w-[7px] h-[7px] rotate-180 text-[8px]"
            icon="iconClose"
          ></SvgIcon>
        </span>
        </div>
      </div>
      <div class="history-row-details">
        <transition
          name="fade"
          mode="out-in"
        >
          <HistoryDetails
            v-if="isDetailsShown"
            :order="order"
          ></HistoryDetails>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/_common/SvgIcon";
import { formatExchangeDetails } from '../_helpers/detailsFormatter';
import { formatHistoryDateTimeToView } from '../_helpers/dateTimeFormatter';
import { formatHistoryOrderStatusToView } from '../_helpers/orderStatusFormatter';
import { formatOrderTypeToHistoryView } from '../_helpers/orderTypeFormatter';
import HistoryDetails from '../HistoryDetails/HistoryDetails';
import { isCrypto } from '@/store/balance/_helpers/currencyTypeHelper';
import { roundingCurrency } from '@/utils/helpers';
import { mapState } from 'vuex';

export default {
  components: {
    HistoryDetails,
    SvgIcon
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isDetailsShown: false,
    };
  },
  computed: {
    ...mapState('user', ['userInfo']),
    isCurrencyGreenColor(){
      const isDeposit = this.order.order_type == 'DEPOSIT'
      const isInvoiceUserEmail = (this.order.order_type == 'INVOICE' || this.order.order_type == 'INTERNAL_MOVEMENT') && this.order?.details?.destination_account == this?.userInfo?.email
      return isDeposit || isInvoiceUserEmail
    },
    isCurrencyRedColor(){
      const isWithdrawal = this.order.order_type == 'WITHDRAWAL'
      const isInvoiceNotUserEmail = (this.order.order_type == 'INVOICE' || this.order.order_type == 'INTERNAL_MOVEMENT') && this.order?.details?.destination_account != this?.userInfo?.email
      return isWithdrawal || isInvoiceNotUserEmail
    },
    isCancelAvailable() {
      const allowedStatuses = ['NEW', 'WAITING_FOR_PRICE'];
      if (this.order) {
        const { order_sub_type, status } = this.order;
        return order_sub_type === 'LIMIT_EXCHANGE' && allowedStatuses.includes(status);
      }
      return false;
    },
    computedAmount() {
      if (this.order && this.order.amount) {
        return roundingCurrency({
          currencyAmount: Math.abs(this.order.amount), 
          currency: this.order.currency,
        });
      }
      return '';
    },
    computedOrderType() {
      return formatOrderTypeToHistoryView(this.order);
    },
    computedHistoryDate() {
      return formatHistoryDateTimeToView(this.order);
    },
    computedHistoryStatus() {
      return formatHistoryOrderStatusToView(this.order);
    },
    isDetailsAvailable() {
      if (this.computedOrderType.includes('deposit') && this.computedHistoryStatus === 'waiting') {
        return false;
      }
      if (
        this.computedOrderType.includes('deposit') &&
        (this.computedHistoryStatus === 'error' || this.computedHistoryStatus === 'expired') &&
        isCrypto(this.order.currency)
      ) {
        return false;
      }
      return true;
    },
    currencyToSpend() {
      return `${roundingCurrency({
        currencyAmount: this.details.currency_to_spend_amount, 
        currency: this.details.currencyToSpend,
        })} ${this.details.currencyToSpend}`
    },
    currencyToGet() {
      return `${roundingCurrency({
        currencyAmount: this.details.currency_to_get_amount, 
        currency: this.details.currencyToGet,
        })} ${this.details.currencyToGet}`        
    },
    details() {
      if (this.order.order_type == 'EXCHANGE') {
        return formatExchangeDetails(this.order);
      } else {
        return this.order.details
      }
    },
  },
  methods: {
    toggleDetails() {
      if (this.isDetailsAvailable) {
        this.isDetailsShown = !this.isDetailsShown;
      }
    },
    handleCancelOrder(e) {
      const { external_id } = this.order;
      e.stopPropagation();
      this.$emit('cancelOrder', external_id);
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';

.history-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 30px 30px;
  row-gap: 4px;

  @media only screen and (min-width: $tablet-width) {
    row-gap: 12px;
    grid-template-rows: 30px;
  }

  &--cancelled {
    opacity: 0.6;
  }

  .history-row-col {
    display: flex;
    align-items: center;
    //justify-content: center;
    font-size: 12px;
    line-height: 14px;
    //width: 25%;
    word-break: break-word;
    padding: 0 6px 0 0;

    &.one-sell {
      grid-column: span 2;

      @media only screen and (min-width: $tablet-width) {
        grid-column: 1;
      }
    }

    &.two-sell {
      grid-column: span 2;
      grid-row: 2;

      @media only screen and (min-width: $tablet-width) {
        grid-column: 2;
        grid-row: 1;
        justify-content: center;
      }
    }

    &.three-sell {
      grid-column: span 2;
      grid-row: 2;
      justify-content: flex-end;
      text-align: right;

      @media only screen and (min-width: $tablet-width) {
        grid-column: 3;
        grid-row: 1;
        justify-content: center;
      }
    }

    &.four-sell {
      grid-column: span 2;

      @media only screen and (min-width: $tablet-width) {
        grid-column: 4;
      }
    }

    @media only screen and (min-width: $large-width) {
      padding: 0 10px 0 0;
      font-size: 14px;
      line-height: 16px;
    }
    @media only screen and (min-width: $wide-width) {
      font-size: 16px;
      line-height: 19px;
    }

    &:first-of-type {
      text-align: left;
      justify-content: flex-start;
    }

    &:last-of-type {
      padding: 0;
    }

    &:nth-of-type(3) {
      > * {
        font-size: 11px;
        @media only screen and (min-width: $tablet-width) {
          font-size: inherit;
        }
      }
    }

    &--status {
      text-align: right;
    }

    &--currency {
      display: block;

      @media only screen and (min-width: $tablet-width) {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      //span {
      //  display: block;
      //  white-space: nowrap;
      //}
    }

    &--type {
      text-transform: capitalize;
    }

    .round-arrow-btn {
      flex-shrink: 0;
    }
  }
}

.history-row-status {
  &--completed {
    color: #fff;
    background-color: #5ABC67;
  }

  &--waiting {
    color: #F0C854;
    background-color: rgba(#F0C854, .1);
  }

  &--new{
    color: #2AF3F3;
    background-color: rgba(#2AF3F3, .1);
  }

  &--cancelled {
    color: #5ABC67;
    background-color: rgba(#5ABC67, .1);
  }

  &--cancelling {
    color: #5ABC67;
    background-color: #FFF;
  }

  &--error {
    color: #fff;
    background-color: #FF556D;
  }

  &--blocked {
    color: #FF556D;
    background-color: rgba(255, 85, 109, 0.1);
    border-color: #FF556D;
  }

  &--expired {
    background-color: rgba(#ff556d, 0.1);
    color: #ff556d;
  }
}
</style>
