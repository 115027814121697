<template>
  <div class="Exchange">
    <div class="Exchange_row Exchange_row--title border-b-[1px] border-[#2af3f3]/[0.1] pt-[23px] pb-[12px] text-[21px] leading-[25px] uppercase text-blue-700 dark:text-white text-left font-medium mb-[15px]">Confirm the exchange</div>
    <div class="mb-[15px]">
      <label class="text-[12px] leading-[14px] 2xl:text-[14px] 2xl:leading-[16px] text-gray-600 uppercase mb-[9px] flex justify-between items-center dark:text-white" for="sell"> Sell </label>
      <div class="relative">
        <input
          class="block text-[12px] leading-[14px] 2xl:text-[14px] 2xl:leading-[16px] w-full px-[12px] h-[46px] rounded-[4px] border-none outline-none dark:bg-white/[0.04] text-blue-700 dark:text-white"
          name="sell"
          disabled
          :value="toSpendValue"
        />
      </div>
    </div>
    <div class="mb-[15px]">
      <label class="text-[12px] leading-[14px] 2xl:text-[14px] 2xl:leading-[16px] text-gray-600 uppercase mb-[9px] flex justify-between items-center dark:text-white" for="buy">Buy</label>
      <div class="relative">
        <input
          class="block text-[12px] leading-[14px] 2xl:text-[14px] 2xl:leading-[16px] w-full px-[12px] h-[46px] rounded-[4px] border-none outline-none dark:bg-white/[0.04] text-blue-700 dark:text-white"
          name="buy"
          disabled
          :value="toGetValue"
        />
      </div>
    </div>
    <div class="mb-[15px]">
      <label class="text-[12px] leading-[14px] 2xl:text-[14px] 2xl:leading-[16px] text-gray-600 uppercase mb-[9px] flex justify-between items-center dark:text-white" for="course">Exchange Rate</label>
      <div class="relative">
        <input
          class="block text-[12px] leading-[14px] 2xl:text-[14px] 2xl:leading-[16px] w-full px-[12px] h-[46px] rounded-[4px] border-none outline-none dark:bg-white/[0.04] text-blue-700 dark:text-white"
          name="buy"
          :value="exchangeData.exchange_price"
          disabled
        />
      </div>
    </div>
    <div class="exchange-btns flex justify-between items-center mb-[20px]">
      <OutlineButton
        class="outline-button-default outline-button-full-width"
        text="Confirm"
        fullWidth
        @click="handleSubmitExchange"
      />
      <Button
        class="btn min-w-[100px]"
        text="cancel"
        size="large"
        @click="handleCancelExchange"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Button from '@/components/_common/FormElements/Button/Button';
import OutlineButton from '@/components/_common/FormElements/OutlineButton/OutlineButton';

export default {
  components: {
    Button,
    OutlineButton,
  },
  props: {
    exchangeType: {
      type: String,
      default: 'market',
    },
  },
  computed: {
    ...mapState('exchange', ['exchangeMarketObject', 'exchangeLimitObject']),
    exchangeData() {
      return this.exchangeType === 'market' ? this.exchangeMarketObject : this.exchangeLimitObject
    },
    toSpendValue() {
      return `${ this.exchangeData.currency_to_spend_amount } ${this.exchangeData.currency_to_spend}`
    },
    toGetValue() {
      return `${ this.exchangeData.currency_to_get_amount } ${ this.exchangeData.currency_to_get }`
    },
  },
  methods: {
    ...mapActions('exchange', ['apiPostExchangeRequest']),
    handleSubmitExchange() {
      this.$emit('submitExchange')
    },
    handleCancelExchange() {
      this.$emit('cancelConfirm')
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.exchange-btns {
  button {
    flex: 0 1 48%;
  }
}
</style>
