import { isEmpty } from 'ramda';
import { isCrypto } from '@/store/balance/_helpers/currencyTypeHelper';

// front end custom order details type to
// show proper history details component
const orderHistoryDetailsTypes = {
  DEPOSIT_GATEWAY_BTC: 'SCAN_TRANSACTION_DETAILS',
  DEPOSIT_GATEWAY_ETH: 'SCAN_TRANSACTION_DETAILS',
  // temporary type for non crystal deposits
  DEPOSIT_GATEWAY_USDT: 'DEFAULT_CRYPTO_DEPOSIT',
  DEPOSIT_GATEWAY_LTC: 'DEFAULT_CRYPTO_DEPOSIT',
  DEPOSIT_GATEWAY_DOGE: 'DEFAULT_CRYPTO_DEPOSIT',
  DEPOSIT_GATEWAY_BNB: 'DEFAULT_CRYPTO_DEPOSIT',
  // DEPOSIT_GATEWAY_USDT: 'SCAN_TRANSACTION_DETAILS',
  WITHDRAWAL_GATEWAY_BTC: 'CRYPTO_WITHDRAWAL_DETAILS',
  WITHDRAWAL_GATEWAY_ETH: 'CRYPTO_WITHDRAWAL_DETAILS',
  WITHDRAWAL_GATEWAY_USDT: 'CRYPTO_WITHDRAWAL_DETAILS',
  WITHDRAWAL_GATEWAY_LTC: 'CRYPTO_WITHDRAWAL_DETAILS',
  WITHDRAWAL_GATEWAY_DOGE: 'CRYPTO_WITHDRAWAL_DETAILS',
  WITHDRAWAL_GATEWAY_BNB: 'CRYPTO_WITHDRAWAL_DETAILS',
  EXCHANGE_LIMIT_EXCHANGE: 'EXCHANGE_DETAILS',
  EXCHANGE_MARKET_EXCHANGE: 'EXCHANGE_DETAILS',
  WITHDRAWAL_SEPA_EUR: 'SEPA_DETAILS',
  DEPOSIT_SEPA_EUR: 'SEPA_DETAILS',
};

export const historyOrderDetailsType = order => {
  if (isEmpty(order)) return '';
  const { order_type, order_sub_type, currency, details } = order;
  let fullOrderTypeName;
  if (order_type === 'EXCHANGE') {
    fullOrderTypeName = `${order_type}_${order_sub_type}`;
  } else if (order_type === 'INVOICE') {
    return order_type;
  } else {
    fullOrderTypeName = `${order_type}_${order_sub_type}_${currency}`;
  }
  // console.log('fix scan')
  if (orderHistoryDetailsTypes[fullOrderTypeName]) {
    if (orderHistoryDetailsTypes[fullOrderTypeName] == 'SCAN_TRANSACTION_DETAILS') {
      // console.log(orderHistoryDetailsTypes[fullOrderTypeName])
      // console.log(details)
      // console.log(isEmpty(details.transactionRisks))
      // console.log(order_type)
      // console.log(currency)
      // console.log(isCrypto(currency))
      // console.log(isEmpty(details.transactionRisks))
      // console.log(isEmpty(details.scan_transaction_details))
      // console.log(details.scan_transaction_details)
      // if (isEmpty(details.transactionRisks) && order_type == 'DEPOSIT' && isCrypto(currency)) {
      if (details.scan_transaction_details == undefined && order_type == 'DEPOSIT' && isCrypto(currency)) {
        return 'DEFAULT_CRYPTO_DEPOSIT';
      }
    }
    return orderHistoryDetailsTypes[fullOrderTypeName];
  } else if ( `${order_type}_${order_sub_type}` == 'WITHDRAWAL_GATEWAY' ) {
    if ( isCrypto(currency) ) {
      return 'CRYPTO_WITHDRAWAL_DETAILS'
    } else {
      return 'FIAT_WITHDRAWAL_DETAILS'
    }
  } else if ( `${order_type}_${order_sub_type}` == 'DEPOSIT_GATEWAY' ) {
    if ( isCrypto(currency) ) {
      return 'DEFAULT_CRYPTO_DEPOSIT';
    } else {
      return 'DEFAULT_FIAT_DEPOSIT';
    }
  } else {
    return 'DEFAULT';
  }
};
