import moment from 'moment';
import { isEmpty } from 'ramda';

const DATE_TIME_HISTORY_VIEW = 'DD.MM.YY HH:mm';

export const formatScanTransactionDetails = order => {
  if (isEmpty(order) || isEmpty(order.details)) return {};
  const {
    details: { scan_transaction_details, ...restDetails },
  } = order;
  if (scan_transaction_details) {
    // destruct scan details to put data on same object level with other info
    const { amount_in_usd, date_added, risk_score, risky_volume_in_usd, ...restScanDetails } = scan_transaction_details;
    const { source_of_funds } = restScanDetails;
    // get rid of zero value risk statements
    const formattedRisks = Object.keys(source_of_funds).reduce((acc, curr) => {
      if (source_of_funds[curr]) {
        const mappedRiskGroup = Object.values(source_of_funds[curr]).filter(riskItem => riskItem.value > 0);
        acc = {
          ...acc,
          [curr]: mappedRiskGroup,
        };
      }
      return acc;
    }, {});
    return {
      ...restDetails,
      amount_in_usd: `$${amount_in_usd}`,
      date_added: moment(date_added).format(DATE_TIME_HISTORY_VIEW),
      risk_score: `Risk score: ${risk_score}%`,
      risky_volume_in_usd: `$${risky_volume_in_usd}`,
      transactionRisks: formattedRisks,
    };
  }
  return {};
};

export const formatExchangeDetails = order => {
  if (isEmpty(order) || isEmpty(order.details)) return {};
  const { currency, details } = order;
  const splitCurrency = currency.split('_');
  return {
    currencyToGet: splitCurrency[0],
    currencyToSpend: splitCurrency[1],
    ...details,
  };
};

export const formatCryptoWithdrawalDetails = order => {
  if (isEmpty(order) || isEmpty(order.details)) return {};
  const { currency, details } = order;
  return {
    currency,
    ...details,
  };
};
