<template>
  <div class="WalletItem_root border-dashed border-b-[1px] border-[#2af3f3]/[0.21]">
    <div class="WalletItem_wrapper">
      <div class="wallet-item-currency self-start flex items-center mb-[12px]">
        <SquareCoinIcon
          class="mr-[5px]"
          :currency="modifiedItem.currency"
          withoutCustomSize
        />
        <span class="inline-block text-[14px] text-blue-700 dark:text-white 2xl:text-[16px] mr-[5px]">{{ currencyFullName }}</span>
        <span class="inline-block text-[14px] text-blue-700 dark:text-[#9395AB] 2xl:text-[16px]">({{ modifiedItem.currency }})</span>
      </div>
      <div class="flex">
        <div v-tooltip="{ classes: ['text-sm'], trigger: 'click hover', content: withdrawalDisabled ? 'CURRENCY DISABLED' : '' }">
          <Button
            text="withdraw"
            :disabled="withdrawalDisabled"
            class="mr-[10px]"
            defaultBtn
            @click="handleWithdraw"
          />
        </div>
        <div v-tooltip="{ classes: ['text-sm'], trigger: 'click hover', content: depositDisabled ? 'CURRENCY DISABLED' : '' }">
          <Button
            text="deposit"
            :disabled="depositDisabled"
            class="btn btn-small btn-bright btn-bright-small min-w-[100px]"
            @click="handleDeposit"
            brightBtn
          />
        </div>
      </div>
      <div class="WalletItem_total mb-[6px] text-[12px] leading-[14px] text-gray-600 dark:text-white sm:text-right sm:mb-[8px] 2xl:text-[14px]">total: {{ modifiedItem.total }} {{ modifiedItem.currency }}</div>
      <div class="WalletItem_reserved mb-[12px] text-[12px] leading-[14px] text-gray-600 dark:text-white sm:text-right sm:m-0 2xl:text-[14px]">reserved: {{ modifiedItem.reserved }} {{ modifiedItem.currency }}</div>
      <div class="WalletItem_totalInAnother text-[14px] leading-[16px] text-right uppercase m-0 text-blue-700 dark:text-blue-500 sm:mt-[12px] 2xl:text-[16px]">{{ modifiedItem.totalInAnotherCurrency }} {{
          modifiedItem.anotherCurrency
        }}
      </div>
    </div>
  </div>
</template>

<script>
import SquareCoinIcon from '@/components/_common/SquareCoinIcon/SquareCoinIcon';
import Button from '@/components/_common/FormElements/Button/Button';
import { allDepositMethodsDisabled } from '@/views/Application/Account/Exchange/Wallet/_helpers/depositRules';
import { allWithdrawalMethodsDisabled } from '@/views/Application/Account/Exchange/Wallet/_helpers/withdrawalRules';
import { roundingCurrency, currencyFullName } from "@/utils/helpers";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    anotherCurrency: {
      type: String,
      required: true,
    },
  },
  components: {
    SquareCoinIcon,
    Button,
  },
  computed: {
    currencyFullName() {
      return currencyFullName(this.item.currency)
    },
    depositDisabled() {
      return allDepositMethodsDisabled(this.item.currency);
    },
    withdrawalDisabled() {
      return allWithdrawalMethodsDisabled(this.item.currency);
    },
    modifiedItem() {
      const wallet = this.item;
      const { currency, ...restWallet } = wallet;
      const { total, reserved } = restWallet[ currency ];
      const totalInAnotherCurrency = (restWallet[ this.anotherCurrency ] && restWallet[ this.anotherCurrency ].total) || 0;

      return {
        currency,
        total: roundingCurrency({ currency, currencyAmount: total }),
        reserved: roundingCurrency({ currency, currencyAmount: reserved }),
        totalInAnotherCurrency: roundingCurrency({
          currency: this.anotherCurrency,
          currencyAmount: totalInAnotherCurrency,
        }),
        anotherCurrency: this.anotherCurrency,
      };
    },
  },
  methods: {
    handleWithdraw() {
      this.$router.push(`/exchange/wallet/withdraw/${ this.item.currency }`);
    },
    handleDeposit() {
      this.$router.push(`/exchange/wallet/deposit/${ this.item.currency }`);
    },
  },

};
</script>

<style lang="scss">
@import './WalletItem.scss';
</style>
