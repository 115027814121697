<template>
  <div class="Exchange">
    <div class="mb-[15px]">
      <label class="flex justify-between items-center text-[12px] leading-[14px] 2xl:text-[14px] 2xl:leading-[16px] text-gray-600 dark:text-white uppercase mb-[9px]" for="sell">
        Sell
        <span>
          Min:
          <span class="text-blue-300 dark:text-blue-500">{{ exchangeMinLimitsByPair }} </span>
          Max:
          <span class="mr-[5px] text-blue-300 dark:text-blue-500">{{ exchangeMaxLimitsByPair }}</span>
        </span>
      </label>
      <div class="relative rounded-[4px] border border-[#E8ECF3] dark:border-none">
        <input
          class="block text-gray-600 dark:text-white w-full pl-[12px] pr-[110px] h-[46px] rounded-[4px] bg-white dark:bg-white/[0.04] border-none outline-none px-[12px] text-[12px] 2xl:text-[14px] 2xl:leading-[16px]"
          type="number"
          name="sell"
          @input="updateUpdateAmount(arguments[0], 'currency_to_spend_amount')"
          :value="exchangeMarketObject.currency_to_spend_amount"
        />
        <CurrencyFilter
          :filterHasAllValue="false"
          currencyFilterStyles="cursor-pointer flex items-center justify-between h-[46px] bg-white dark:border-none dark:bg-transparent items-center rounded-[4px] px-[12px]"
          emitField="currency_to_spend"
          :inlineRight="true"
          :filterHasLabel="false"
          :defaulCurrency="exchangeMarketObject.currency_to_spend"
          :defaulCurrencyList="computedCurrencyToSpendFormatter"
          @change="updateSelectCurrency"
        />
      </div>
    </div>
    <Percentage
      class="mb-[15px]"
      @click="handleClickPercentage"
      @swapCurrencies="handleSwapCurrencies"
    />
    <div class="mb-[15px]">
      <label class="flex justify-between items-center text-[12px] leading-[14px] 2xl:text-[14px] 2xl:leading-[16px] text-gray-600 dark:text-white uppercase mb-[9px]" for="buy">
        Buy
        <span>
          Fee:
          <span class="text-blue-300 dark:text-blue-500"> {{ exchangeMarketFees }} {{ exchangePairFees }} </span>
        </span>
      </label>
      <div class="relative rounded-[4px] border border-[#E8ECF3] dark:border-none">
        <input
          class="block text-gray-600 dark:text-white w-full pl-[12px] pr-[110px] h-[46px] rounded-[4px] bg-white dark:bg-white/[0.04] border-none outline-none px-[12px] text-[12px] 2xl:text-[14px] 2xl:leading-[16px]"
          type="number"
          name="buy"
          :value="exchangeMarketObject.currency_to_get_amount"
          @input="updateUpdateAmount(arguments[0], 'currency_to_get_amount')"
        />
        <CurrencyFilter
          currencyFilterStyles="cursor-pointer flex items-center justify-between h-[46px] bg-white dark:border-none dark:bg-transparent items-center rounded-[4px] px-[12px]"
          emitField="currency_to_get"
          :filterHasAllValue="false"
          :inlineRight="true"
          :filterHasLabel="false"
          :defaulCurrency="exchangeMarketObject.currency_to_get"
          :defaulCurrencyList="computedCurrencyToGetFormatter"
          @change="updateSelectCurrency"
        />
      </div>
    </div>
    <div class="mb-[15px]">
      <label
        class="flex justify-between items-center text-[12px] leading-[14px] 2xl:text-[14px] 2xl:leading-[16px] text-gray-600 dark:text-white uppercase mb-[9px]"
        for="course"
      >
        Exchange Rate
      </label>
      <div class="relative rounded-[4px] border border-[#E8ECF3] dark:border-none">
        <input
          class="block text-gray-600 dark:text-white w-full h-[46px] rounded-[4px] bg-white dark:bg-white/[0.04] border-none outline-none pl-[12px] pr-[50px] text-[12px] 2xl:text-[14px] 2xl:leading-[16px]"
          type="number"
          name="buy"
          :value="exchangeMarketObject.exchange_price"
          disabled
        />
      </div>
    </div>
    <div class="exchange-balance py-[16px] text-left text-[18px] leading-[21px] text-blue-700 dark:text-white">
      Balance:
      <span class="text-[18px] leading-[21px] text-blue-300 dark:text-blue-500">
        {{ availableBalanceByCurrency }} {{ exchangeMarketObject.currency_to_spend }}
      </span>
    </div>
    <OutlineButton
      class="outline-button-default outline-button-full-width"
      text="EXCHANGE"
      @click="handleSubmitExchange"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { getBalanceByCurrency } from '@/components/_common/Wallet/_helpers/userBalances';
import { getExchangeLimitsByPair } from '@/store/exchange/_helpers/exchangeLimits';
import OutlineButton from '@/components/_common/FormElements/OutlineButton/OutlineButton';
import Percentage from '../Percentage/Percentage';
import CurrencyFilter from '@/components/_common/ExtraFilters/CurrencyFilter/CurrencyFilter';
import { roundingCurrency, getCurrenciesListToHide } from "@/utils/helpers"

export default {
  components: {
    Percentage,
    OutlineButton,
    CurrencyFilter,
  },
  computed: {
    ...mapState('user', ['userExchangeRules', 'userExchangeFees']),
    ...mapState('exchange', ['currencies', 'exchangeMarketObject']),
    availableBalanceByCurrency() {
      return roundingCurrency({currencyAmount: getBalanceByCurrency(this.exchangeMarketObject.currency_to_spend), currency: this.exchangeMarketObject.currency_to_spend})
    },
    exchangeMaxLimitsByPair() {
      return getExchangeLimitsByPair('max')
    },
    exchangeMinLimitsByPair() {
      return getExchangeLimitsByPair('min')
    },
    allActiveExchangePairs() {
      return Object.keys(this.userExchangeRules).reduce((acc, curr) => {
        const { is_market_exchange_enabled } = this.userExchangeRules[curr]
        if (is_market_exchange_enabled) acc.push(curr)
        // acc.push(curr)
        return acc
      }, [])
    },
    computedCurrencyToSpend() {
      return this.currencies.reduce((acc, curr) => {
        const atleastSingleExchangeExists = this.allActiveExchangePairs.find(pair => pair.includes(`_${curr}`))
        if (atleastSingleExchangeExists) acc.push(curr)
        return acc
      }, [])
    },
    computedCurrencyToGet() {
      const { currency_to_spend } = this.exchangeMarketObject
      return this.allActiveExchangePairs.reduce((acc, curr) => {
        const currData = curr.split('_')
        const currToGet = currData[0]
        if (currData[1] == currency_to_spend) {
          if (this.currencies.includes(currToGet)) acc.push(currToGet)
        }
        return acc.filter(currency=>getCurrenciesListToHide().every(el => el !== currency))
      }, [])
    },
    isExchangeFormValid() {
      const { currency_to_get_amount } = this.exchangeMarketObject
      return !!currency_to_get_amount
    },
    exchangeMarketFees() {
      let fees = this.userExchangeFees[`${this.exchangeMarketObject.currency_to_get}_${this.exchangeMarketObject.currency_to_spend}`]?.MARKET_EXCHANGE;
      if (fees == undefined) return '-'
      if (fees.percent_fee && fees.static_fee) {
        return fees.percent_fee + '% + ' + fees.static_fee;
      } else if (fees.percent_fee && !fees.static_fee) {
        return fees.percent_fee + '%'
      } else if (!fees.percent_fee && fees.static_fee) {
        return fees.static_fee
      }
      return '0';
    },
    exchangePairFees() {
      if (this.exchangeMarketObject.currency_to_spend_amount && this.exchangeMarketObject.currency_to_get_amount) {
        return this?.exchangeMarketObject?.fee ? `(${roundingCurrency({currency: this.exchangeMarketObject.currency_to_spend, currencyAmount: this.exchangeMarketObject.fee}) } ${this.exchangeMarketObject.currency_to_spend})` : '';
      }
      return '';
    },
    computedCurrencyToSpendFormatter() {
      return this.computedCurrencyToSpend.map(currency=> { return { key: currency, value: currency }})
    },
    computedCurrencyToGetFormatter() {
      return this.computedCurrencyToGet.map(currency=> { return { key: currency, value: currency }})
    },
  },
  methods: {
    ...mapActions('exchange', [
      'updateExchangeMarketObject',
      'apiGetMarketExchangeCalculated',
      'apiPostExchangeRequest',
      'setExchangeRate'
    ]),
    handleClickPercentage(value) {
      const feePercent = this.userExchangeFees[`${this.exchangeMarketObject.currency_to_get}_${this.exchangeMarketObject.currency_to_spend}`]?.MARKET_EXCHANGE?.percent_fee
      const currency_to_spend_amount = Number(value) * Number(getBalanceByCurrency(this.exchangeMarketObject.currency_to_spend));
      const calcValueWithFeePercent = currency_to_spend_amount * ((100 - feePercent) / 100)
      this.updateExchangeMarketObject({
        value: calcValueWithFeePercent,
        field: 'currency_to_spend_amount',
      })
      if (Number(value) > 0) this.handleShouldCalculate('currency_to_spend_amount', true)
    },
    // TODO: mb need async
    handleSwapCurrencies() {
      const currToGet = this.exchangeMarketObject.currency_to_get
      const currToSpend = this.exchangeMarketObject.currency_to_spend
      this.updateExchangeMarketObject({
        field: 'currency_to_spend',
        value: currToGet,
      });
      this.updateExchangeMarketObject({
        field: 'currency_to_get',
        value: currToSpend,
      });
      // TODO: mb need await
      this.setExchangeRate();
      if (Number(this.exchangeMarketObject.currency_to_spend_amount) > 0) {
        this.handleShouldCalculate('currency_to_spend')
      }
    },
    // TODO: mb need async
    updateSelectCurrency({ field, value }) {
      if (field === 'currency_to_spend' && !this.isSelectedPairExists(value)) {
        this.currToSpendExceptionHandler({ field, value })
      } else {
        this.updateExchangeMarketObject({
          field,
          value,
        });
        // TODO: mb need await
        this.setExchangeRate()
        this.handleShouldCalculate(field)
      }
    },
    currToSpendExceptionHandler({ field, value }) {
      const possiblePair = this.allActiveExchangePairs.find(el => el.includes(`_${value}`));
      const neededCurr = (possiblePair && possiblePair.split('_')[0]) || false;
      if (neededCurr) {
        this.updateExchangeMarketObject({
          value,
          field,
        });
        this.updateExchangeMarketObject({
          value: neededCurr,
          field: 'currency_to_get',
        });
        this.handleShouldCalculate(field)
      }
    },
    isSelectedPairExists(curr) {
      const { currency_to_get } = this.exchangeMarketObject
      const pair = `${currency_to_get}_${curr}`
      return !!this.allActiveExchangePairs.find(el => el === pair)
    },
    updateUpdateAmount(el, field) {
      const value = el.target.value
      this.updateExchangeMarketObject({
        value,
        field,
      })
      if (Number(value) > 0) {
        this.handleShouldCalculate(field, true)
      } else if (value == '') {
        this.resetAmount()
      }
    },
    resetAmount() {
      this.updateExchangeMarketObject({
        value: '',
        field: 'currency_to_spend_amount',
      });
      this.updateExchangeMarketObject({
        value: '',
        field: 'currency_to_get_amount',
      })
    },
    handleShouldCalculate(field, debounce = false) {
      if (debounce) {
        if (this.inputTimer) clearTimeout(this.inputTimer)
        this.inputTimer = setTimeout(() => {
          this.apiGetMarketExchangeCalculated(field)
        }, 500)
      } else {
        this.apiGetMarketExchangeCalculated(field)
      }
    },
    handleSubmitExchange() {
      if (this.isExchangeFormValid) {
        this.$emit('setConfirmStep')
      } else {
        this.apiPostExchangeRequest('market')
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import '@/assets/scss/_variables.scss';
.Exchange_row {
  margin-bottom: 15px;
  .Exchange_input-relative {
    position: relative;

    .multiselect {
      position: absolute;
      right: 10px;
      top: 0;
      width: 50px;
      .multiselect__select {
        padding: 0;
        margin: 0;
        width: auto;
        height: 100%;
        &:before {
          top: 60%;
        }
      }
      .multiselect__tags {
        border: none;
        background: transparent;
        padding: 0;
      }
      .multiselect__single {
        line-height: 46px;
        margin: 0;
        background: transparent;
        color: #fff;
      }
      .multiselect__content-wrapper {
        width: 60px;
        z-index: 5;
        .multiselect__option {
          padding: 5px 12px;
          min-height: 26px;
          background: rgba(42, 243, 243, 0.2);
          &--highlight {
            background: #102032;
          }
          &--selected {
            background: #50c5e9;
          }
        }
      }
    }
  }
}

.exchange-balance {
  border-top: 1px dashed rgba(#2af3f3, 0.21);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.Exchange_select {
  &.multiselect--disabled {
    opacity: 1;
    background: transparent;

    .multiselect__select {
      display: none;
    }
  }
}
</style>
