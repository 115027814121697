import store from '@/store';

export const getExchangeLimitsByPair = (limit, type = 'market') => {
  const {
    state: {
      exchange: { exchangeMarketObject, exchangeLimitObject },
      user: { userExchangeLimits },
    },
  } = store;
  const exchangeObject = type === 'market' ? exchangeMarketObject : exchangeLimitObject;
  const { currency_to_get, currency_to_spend } = exchangeObject;
  const exchangePair = `${currency_to_get}_${currency_to_spend}`;
  if (userExchangeLimits) {
    const maxLimit = (userExchangeLimits && userExchangeLimits[exchangePair] && userExchangeLimits[exchangePair][limit + `_amount`]) || 0;
    return maxLimit;
  }
  return 0;
};

// :TO DO move getLimitsByCurrency helper to another place
export const getLimitsByCurrency = ({ currency, operation = 'deposit', type = 'GATEWAY' }) => {
  const {
    state: {
      user: { userLimits },
    },
  } = store;

  if (userLimits) {
    let limits = {};
    const limitsData = userLimits?.[operation]?.[currency]?.[type] || {}
    if (operation === 'deposit' && (type === 'GATEWAY' || type === 'SEPA')) {
      limits = limitsData?.['P2P']?.CARD || limitsData?.['P2P'];
    } else {
      limits = limitsData;
    }
    return limits;
  }
  return {};
};
