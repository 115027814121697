<template>
  <div class="exchange-heading flex justify-between items-center h-[50px] shadow-providers-history-row-root">
    <div
      :class="[
        'exchange-tab h-full font-medium text-[18px] leading-[48px] dark:text-white text-center cursor-pointer',
        {
          ['exchange-tab-active cursor-default']: activeTab === 'market',
        },
      ]"
      @click="changeTab('market')"
    >
      Market
    </div>
    <div
      :class="[
        'exchange-tab h-full font-medium text-[18px] leading-[48px] dark:text-white text-center cursor-pointer',
        {
          ['exchange-tab-active']: activeTab === 'limit',
        },
      ]"
      @click="changeTab('limit')"
    >
      Limit
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeTab: {
      type: String,
      default: 'market',
    },
  },
  methods: {
    changeTab(tab) {
      this.$emit('updateTab', tab)
    },
  },
};
</script>

<style lang="scss" scoped>
.exchange-heading {
  .exchange-tab {
    text-shadow: 0 0 10px #102032;
    flex: 1 0 0;

    &.exchange-tab-active {
      background: rgba(#2af3f3, 0.2);
      color: #2af3f3;
      transition: 0.2s all ease;
      flex: 0 0 38%;
    }
  }
}
</style>
