<template>
  <div class="Exchange">
    <div class="mb-[15px]">
      <label class="flex justify-between items-center text-[12px] leading-[14px] 2xl:text-[14px] 2xl:leading-[16px] text-gray-600 dark:text-white uppercase mb-[9px]" for="sell">
        Sell
        <span>
          Min:
          <span class="text-blue-300 dark:text-blue-500">{{ exchangeMinLimitsByPair }} </span>
          Max:
          <span class="text-blue-300 dark:text-blue-500">{{ exchangeMaxLimitsByPair }}</span>
        </span>
      </label>
      <div class="relative rounded-[4px] border border-[#E8ECF3] dark:border-none">
        <input
          class="block text-gray-600 dark:text-white w-full pl-[12px] pr-[110px] h-[46px] rounded-[4px] bg-white dark:bg-white/[0.04] border-none outline-none px-[12px] text-[12px] 2xl:text-[14px] 2xl:leading-[16px]"
          type="number"
          name="sell"
          @input="updateUpdateAmount(arguments[0], 'currency_to_spend_amount')"
          :value="exchangeLimitObject.currency_to_spend_amount"
        />
        <CurrencyFilter
          currencyFilterStyles="cursor-pointer flex items-center justify-between h-[46px] bg-white dark:border-none dark:bg-transparent items-center rounded-[4px] px-[12px]"
          emitField="currency_to_spend"
          :filterHasAllValue="false"
          :inlineRight="true"
          :filterHasLabel="false"
          :defaulCurrency="exchangeLimitObject.currency_to_spend"
          :defaulCurrencyList="computedCurrencyToSpendFormatter"
          @change="updateSelectCurrency"
        />
      </div>
    </div>
    <Percentage
      class="mb-[15px]"
      @click="handleClickPercentage"
      @swapCurrencies="handleSwapCurrencies"
    />
    <div class="mb-[15px]">
      <label class="flex justify-between items-center text-[12px] leading-[14px] 2xl:text-[14px] 2xl:leading-[16px] text-gray-600 dark:text-white uppercase mb-[9px]" for="buy">
        Buy
        <span>
          Fee:
          <span class="text-blue-300 dark:text-blue-500"> {{ exchangeMarketFees }} {{ exchangePairFees }} </span>
        </span>
      </label>
      <div class="relative rounded-[4px] border border-[#E8ECF3] dark:border-none">
        <input
          class="block text-gray-600 dark:text-white w-full pl-[12px] pr-[110px] h-[46px] rounded-[4px] bg-white dark:bg-white/[0.04] border-none outline-none px-[12px] text-[12px] 2xl:text-[14px] 2xl:leading-[16px]"
          type="number"
          name="buy"
          :value="exchangeLimitObject.currency_to_get_amount"
          @input="updateUpdateAmount(arguments[0], 'currency_to_get_amount')"
        />
        <CurrencyFilter
          currencyFilterStyles="cursor-pointer flex items-center justify-between h-[46px] bg-white dark:border-none dark:bg-transparent items-center rounded-[4px] px-[12px]"
          emitField="currency_to_get"
          :filterHasAllValue="false"
          :inlineRight="true"
          :filterHasLabel="false"
          :defaulCurrency="exchangeLimitObject.currency_to_get"
          :defaulCurrencyList="computedCurrencyToGetFormatter"
          @change="updateSelectCurrency"
        />
      </div>
    </div>
    <div class="mb-[15px]">
      <label class="flex justify-between items-center text-[12px] leading-[14px] 2xl:text-[14px] 2xl:leading-[16px] text-gray-600 dark:text-white uppercase mb-[9px]" for="course">Exchange Rate</label>
      <div class="relative rounded-[4px] border border-[#E8ECF3] dark:border-none">
        <input
          class="block text-gray-600 dark:text-white w-full pl-[12px] pr-[110px] h-[46px] rounded-[4px] bg-white dark:bg-white/[0.04] border-none outline-none px-[12px] text-[12px] 2xl:text-[14px] 2xl:leading-[16px]"
          type="number"
          required
          name="buy"
          :value="exchangeLimitObject.exchange_price"
          @input="updateUpdateAmount(arguments[0], 'exchange_price')"
        />
      </div>
    </div>
    <div class="exchange-balance py-[16px] text-left text-[18px] leading-[21px] text-blue-700 dark:text-white">
      Balance:
      <span class="text-[18px] leading-[21px] text-blue-300 dark:text-blue-500">
        {{ availableBalanceByCurrency }} {{ exchangeLimitObject.currency_to_spend }}
      </span>
    </div>
    <OutlineButton
      class="outline-button-default outline-button-full-width"
      text="EXCHANGE"
      @click="handleSubmitExchange"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { getBalanceByCurrency } from '@/components/_common/Wallet/_helpers/userBalances';
import { getExchangeLimitsByPair } from '@/store/exchange/_helpers/exchangeLimits';
import OutlineButton from '@/components/_common/FormElements/OutlineButton/OutlineButton';
import Select from '@/components/_common/FormElements/Select/Select';
import Percentage from '../Percentage/Percentage';
import CurrencyFilter from '@/components/_common/ExtraFilters/CurrencyFilter/CurrencyFilter';
import { roundingCurrency, getCurrenciesListToHide } from "@/utils/helpers"
import { showAppNotification } from '@/utils/appNotification';

export default {
  components: {
    Select,
    Percentage,
    OutlineButton,
    CurrencyFilter,
  },
  computed: {
    ...mapState('user', ['userExchangeRules', 'userExchangeFees']),
    ...mapState('exchange', ['currencies', 'exchangeLimitObject']),
    availableBalanceByCurrency() {
      return roundingCurrency({currencyAmount: getBalanceByCurrency(this.exchangeLimitObject.currency_to_spend), currency: this.exchangeLimitObject.currency_to_spend})
    },
    exchangeMaxLimitsByPair() {
      return getExchangeLimitsByPair('max', 'limit')
    },
    exchangeMinLimitsByPair() {
      return getExchangeLimitsByPair('min', 'limit')
    },
    allActiveExchangePairs() {
      return Object.keys(this.userExchangeRules).reduce((acc, curr) => {
        const { is_limit_exchange_enabled } = this.userExchangeRules[curr]
        if ( is_limit_exchange_enabled ) acc.push(curr)
        return acc
      }, [])
    },
    computedCurrencyToSpend() {
      return this.currencies.reduce((acc, curr) => {
        const atleastSingleExchangeExists = this.allActiveExchangePairs.find(pair => pair.includes(`_${curr}`))
        if (atleastSingleExchangeExists) acc.push(curr)
        return acc
      }, [])
    },
    computedCurrencyToGet() {
      const { currency_to_spend } = this.exchangeLimitObject
      return this.allActiveExchangePairs.reduce((acc, curr) => {
        const currData = curr.split('_')
        const currToGet = currData[0]
        if (currData[1] == currency_to_spend) {
          if (this.currencies.includes(currToGet)) acc.push(currToGet)
        }
        return acc.filter(currency=>getCurrenciesListToHide().every(el => el !== currency))
      }, [])
    },
    isExchangeFormValid() {
      const { currency_to_get_amount, exchange_price } = this.exchangeLimitObject
      console.log(currency_to_get_amount, exchange_price)
      return currency_to_get_amount !== '' && exchange_price !== ''
    },
    exchangeMarketFees() {
      let fees =
        this.userExchangeFees[`${ this.exchangeLimitObject.currency_to_get }_${ this.exchangeLimitObject.currency_to_spend }`]?.MARKET_EXCHANGE
      if (fees == undefined) return '-'
      if (fees.percent_fee && fees.static_fee) {
        return fees.percent_fee + '% + ' + fees.static_fee
      } else if (fees.percent_fee && !fees.static_fee) {
        return fees.percent_fee + '%'
      } else if (!fees.percent_fee && fees.static_fee) {
        return fees.static_fee
      }
      return '0';
    },
    exchangePairFees() {
      if (this.exchangeLimitObject.currency_to_spend_amount && this.exchangeLimitObject.currency_to_get_amount) {
        return this?.exchangeLimitObject?.fee ? `(${roundingCurrency({currencyAmount:this.exchangeLimitObject.fee, currency: this.exchangeLimitObject.currency_to_spend})} ${this.exchangeLimitObject.currency_to_spend})` : '';
      }
      return '';
    },
    computedCurrencyToSpendFormatter() {
      return this.computedCurrencyToSpend.map(currency => { return { key: currency, value: currency }})
    },
    computedCurrencyToGetFormatter() {
      return this.computedCurrencyToGet.map(currency => { return { key: currency, value: currency }})
    },
  },
  methods: {
    ...mapActions('exchange', ['updateExchangeLimitObject', 'apiGetLimitExchangeCalculated', 'apiPostExchangeRequest', 'setExchangeRate']),
    handleClickPercentage(value) {
      const feePercent = this.userExchangeFees[`${this.exchangeLimitObject.currency_to_get}_${this.exchangeLimitObject.currency_to_spend}`]?.LIMIT_EXCHANGE?.percent_fee
      const currency_to_spend_amount = Number(value) * Number(getBalanceByCurrency(this.exchangeLimitObject.currency_to_spend));
      const calcValueWithFeePercent = currency_to_spend_amount * ((100 - feePercent) / 100)
      this.updateExchangeLimitObject({
        value: calcValueWithFeePercent,
        field: 'currency_to_spend_amount',
      });
      if (Number(value) > 0) this.handleShouldCalculate('currency_to_spend_amount', true)
    },
    // TODO: mb need async
    handleSwapCurrencies() {
      const currToGet = this.exchangeLimitObject.currency_to_get
      const currToSpend = this.exchangeLimitObject.currency_to_spend
      this.updateExchangeLimitObject({
        field: 'currency_to_spend',
        value: currToGet,
      });
      this.updateExchangeLimitObject({
        field: 'currency_to_get',
        value: currToSpend,
      });
      // TODO: mb need await
      this.setExchangeRate();
      if (Number(this.exchangeLimitObject.currency_to_spend_amount) > 0) {
        this.handleShouldCalculate('currency_to_spend');
      }
    },
    // TODO: mb need async
    updateSelectCurrency({ value, field }) {
      if (field === 'currency_to_spend' && !this.isSelectedPairExists(value)) {
        this.currToSpendExceptionHandler({ field, value })
      } else {
        this.updateExchangeLimitObject({
          value,
          field,
        });
        // TODO: mb need await
        this.setExchangeRate();
        this.handleShouldCalculate(field)
      }
    },
    currToSpendExceptionHandler({ field, value }) {
      const possiblePair = this.allActiveExchangePairs.find(el => el.includes(`_${value}`))
      const neededCurr = (possiblePair && possiblePair.split('_')[0]) || false
      if (neededCurr) {
        this.updateExchangeLimitObject({
          value,
          field,
        });
        this.updateExchangeLimitObject({
          value: neededCurr,
          field: 'currency_to_get',
        });
        this.handleShouldCalculate(field);
      }
    },
    isSelectedPairExists(curr) {
      const { currency_to_get } = this.exchangeLimitObject
      const pair = `${currency_to_get}_${curr}`
      return !!this.allActiveExchangePairs.find(el => el === pair)
    },
    updateUpdateAmount(el, field) {
      const value = el.target.value;
      this.updateExchangeLimitObject({
        value,
        field,
      });
      if (Number(value) > 0) {
        this.handleShouldCalculate(field, true)
      } else if (value == '' && field !== "exchange_price") {
        this.resetAmount()
      }
    },
    resetAmount() {
      this.updateExchangeLimitObject({
        value: '',
        field: 'currency_to_spend_amount',
      });
      this.updateExchangeLimitObject({
        value: '',
        field: 'currency_to_get_amount',
      });
    },
    handleShouldCalculate(field, debounce = false) {
      if (debounce) {
        if (this.inputTimer) clearTimeout(this.inputTimer)
        this.inputTimer = setTimeout(() => {
          this.apiGetLimitExchangeCalculated(field)
        }, 500);
      } else {
        this.apiGetLimitExchangeCalculated(field)
      }
    },
    handleSubmitExchange() {
      if (this.isExchangeFormValid) {
        this.$emit('setConfirmStep')
      } else {
        // this.apiPostExchangeRequest('limit')
        showAppNotification('error', "Please fill all fields");
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.Exchange_row {
  .exchange-input-relative {
    .multiselect {
      position: absolute;
      right: 10px;
      top: 0;
      width: 50px;

      .multiselect__select {
        padding: 0;
        margin: 0;
        width: auto;
        height: 100%;

        &:before {
          top: 60%;
        }
      }

      .multiselect__tags {
        border: none;
        background: transparent;
        padding: 0;
      }

      .multiselect__single {
        line-height: 46px;
        margin: 0;
        background: transparent;
        color: #fff;
      }

      .multiselect__content-wrapper {
        width: 60px;
        z-index: 5;

        .multiselect__option {
          padding: 5px 12px;
          min-height: 26px;
          background: rgba(42, 243, 243, 0.2);

          &--highlight {
            background: #102032;
          }

          &--selected {
            background: #50c5e9;
          }
        }
      }
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.Exchange_select {
  &.multiselect--disabled {
    opacity: 1;
    background: transparent;

    .multiselect__select {
      display: none;
    }
  }
}
</style>
