<template>
  <div class="Percentage">
    <div class="flex items-center justify-center min-h-[20px]">
      <button
        class="w-[60px] h-[30px] bg-blue-700/[0.03] hover:bg-blue-300/[0.1] text-blue-700 hover:text-blue-300 dark:bg-blue-500/[0.2] dark:text-blue-500 dark:hover:shadow-btn-hover-default dark:hover:bg-blue-500 dark:hover:text-blue-800 font-medium transition mr-[10px] last:mr-0 rounded-[2px] text-[12px] leading-[14px] 2xl:text-[14px] 2xlleading-[16px] border-none outline-none cursor-pointer"
        v-for="item in items"
        :key="item.id"
        @click="handlePick(item.value)"
      >
        {{ item.text }}
      </button>
      <div
        class="flex w-[50px] h-[30px] bg-blue-300 dark:bg-blue-500 border-none outline-none cursor-pointer text-blue-800 font-medium rounded-[2px]"
        @click="handleSwapCurrencies">
        <div class="flex items-center justify-center w-[25px] h-[25px] rounded-full bg-white dark:bg-blue-800 m-auto">
          <span class="w-[12px] h-[14px] inline-flex text-blue-300 dark:text-blue-500 rotate-90">
            <SvgIcon
              icon="swapArrows"
            ></SvgIcon>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/_common/SvgIcon";
export default {
  name: 'Percentage',
  components: {
    SvgIcon
  },
  data() {
    return {
      items: [
        {
          id: 0,
          text: '25%',
          value: 0.25,
        },
        {
          id: 1,
          text: '50%',
          value: 0.5,
        },
        {
          id: 2,
          text: '75%',
          value: 0.75,
        },
        {
          id: 3,
          text: '100%',
          value: 1,
        },
      ],
    };
  },
  methods: {
    handlePick(value) {
      this.$emit('click', value);
    },
    handleSwapCurrencies() {
      this.$emit('swapCurrencies');
    },
  },
};
</script>