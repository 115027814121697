<template>
  <div class="crypto-withdraw-details-container">
    <div class="text-left break-all">
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Internal Id:</span>
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ order.internal_id }}</span>
    </div>
    <div class="text-left break-all">
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Wallet address:</span>
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ details.address }}</span>
    </div>
    <div class="text-left break-all" v-if="details.comment">
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Comment:</span>
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ details.comment }}</span>
    </div>
    <div class="text-left break-all" v-if="details.tr_hash">
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Transaction hash:</span>
      <a
        class="ml-[3px] text-[#2af1f1] no-underline dark:text-blue-500 text-[14px] leading-[16px] 2xl:text-[16px] 2xl:leading-[18px]"
        :href="details.tr_hash"
        target="_blank"
      >
        link hash transaction
      </a>
    </div>
    <div class="text-left break-all" v-else-if="details.tr_id">
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Id:</span>
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ details.tr_id }}</span>
    </div>
    <div class="text-left break-all">
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Fee:</span>
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ feeFormatted }} {{ details.currency }}</span>
    </div>
    <div
      class="text-left break-all"
      v-if="formatedAccauntBalance"
    >
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Account balance:</span>
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ formatedAccauntBalance }}</span>
    </div>
  </div>
</template>

<script>
import { formatCryptoWithdrawalDetails } from '../_helpers/detailsFormatter';
import { roundingCurrency } from '@/utils/helpers';

export default {
  props: {
    order: {
      type: [Object, Array],
      required: true,
    },
  },
  computed: {
    details() {
      return formatCryptoWithdrawalDetails(this.order);
    },
    feeFormatted() {
      return roundingCurrency({
        currencyAmount: this.details.fee,
        currency: this.details.currency,
      })
    },
    formatedAccauntBalance() {
      if(this.order.account_balance) {
        return Object.entries(this.order.account_balance).map(([currency, currencyAmount]) => `${roundingCurrency({ currencyAmount, currency })} ${currency}`).join(', ')
      }

      return false
    },
  },
};
</script>
