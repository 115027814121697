<template>
  <div class="border-[2px] border-[#213b57] shadow-component-wrapper-root rounded-b-[4px]">
    <div class="py-14 px-10 md:py-20 md:px-20">
      <ScanTransaction
        v-if="isScanTransaction"
        :order="order"
      />
      <ExchangeDetails
        v-if="isExchange"
        :order="order"
      />
      <SepaDetails
        v-if="isSepa"
        :order="order"
      />
      <CryptoWithdrawDetails
        v-if="isCryptoWithdraw"
        :order="order"
      />
      <FiatWithdrawDetails
        v-if="isFiatWithdraw"
        :order="order"
      />
      <CryptoDepositDetails
        v-if="isDefaultCryptoDeposit"
        :order="order"
      />
      <FiatDepositDetails
        v-if="isDefaultFiatDeposit"
        :order="order"
      />
      <InvoiceDetails
        v-if="isInvoice"
        :order="order"
      />
      <InternalMovementDetails
        v-if="isInternalMovement"
        :order="order"
      />
      <DepositGatewayDetails
        v-if="isDepositGateway"
        :order="order"
      />
      <button
        class="m-btn mr-auto block"
        v-if="order.order_type === 'WITHDRAWAL' && (order.order_sub_type === 'SWIFT' || order.order_sub_type === 'SEPA')"
        @click="downloadPdf"
      >
        download receipt
      </button>
      <DetailsSwiftSepaPDF
        v-show="isShowPdf"
        ref="pdfComponent"
        :info="order"
      />
      <CashboxDetails
        v-if="isCashbox"
        :order="order"
      />
      <SepaDetails
        v-if="isSwiftTransaction"
        :order="order"
      />
    </div>
  </div>
</template>

<script>
import { historyOrderDetailsType } from "../_helpers/historyOrderDetailsType"
import ScanTransaction from "./ScanTransaction/ScanTransaction"
import ExchangeDetails from "./ExchangeDetails"
import SepaDetails from "./SepaDetails"
import CryptoWithdrawDetails from "./CryptoWithdrawDetails"
import FiatWithdrawDetails from "./FiatWithdrawDetails"
import CryptoDepositDetails from "./CryptoDepositDetails"
import FiatDepositDetails from "./FiatDepositDetails"
import InvoiceDetails from "./InvoiceDetails"
import InternalMovementDetails from "./InternalMovementDetails/InternalMovementDetails"
import DepositGatewayDetails from "./DepositGatewayDetails"
import { jsPDF } from "jspdf"
import DetailsSwiftSepaPDF from "./DetailsSwiftSepaPDF"
import CashboxDetails from './CashboxDetails';

export default {
  components: {
    InternalMovementDetails,
    DepositGatewayDetails,
    DetailsSwiftSepaPDF,
    ScanTransaction,
    ExchangeDetails,
    SepaDetails,
    CryptoWithdrawDetails,
    CryptoDepositDetails,
    InvoiceDetails,
    FiatWithdrawDetails,
    FiatDepositDetails,
    CashboxDetails
  },

  props: {
    order: {
      type: [ Object, Array ],
      required: true
    }
  },

  computed: {
    isSwiftTransaction() {
      return this.order.order_sub_type === 'SWIFT' && this.order.order_type === 'DEPOSIT';
    },
    isScanTransaction() {
      const detailsType = historyOrderDetailsType( this.order )
      return detailsType === "SCAN_TRANSACTION_DETAILS"
    },
    isExchange() {
      const detailsType = historyOrderDetailsType( this.order )
      return detailsType === "EXCHANGE_DETAILS"
    },
    isInvoice() {
      const detailsType = historyOrderDetailsType( this.order )
      return detailsType === "INVOICE"
    },
    isSepa() {
      const detailsType = historyOrderDetailsType( this.order )
      return detailsType === "SEPA_DETAILS"
    },
    isCryptoWithdraw() {
      const detailsType = historyOrderDetailsType( this.order )
      return detailsType === "CRYPTO_WITHDRAWAL_DETAILS"
    },
    isFiatWithdraw() {
      const detailsType = historyOrderDetailsType( this.order )
      return detailsType === "FIAT_WITHDRAWAL_DETAILS"
    },
    isDefaultCryptoDeposit() {
      const detailsType = historyOrderDetailsType( this.order )
      return detailsType === "DEFAULT_CRYPTO_DEPOSIT"
    },
    isInternalMovement() {
      return this.order.order_type === "INTERNAL_MOVEMENT"
    },
    isDepositGateway() {
      // return this.order.order_type === 'DEPOSIT' && this.order.order_sub_type == 'GATEWAY'
      return false
    },
    isDefaultFiatDeposit() {
      const detailsType = historyOrderDetailsType( this.order )
      // const isDepositCashbox = this.order.order_type === "DEPOSIT" && this.order.order_sub_type == "CASHBOX"
      // return detailsType === "DEFAULT_FIAT_DEPOSIT" || isDepositCashbox
      return detailsType === "DEFAULT_FIAT_DEPOSIT"
    },
    isCashbox() {
      return this.order.order_sub_type === "CASHBOX"
    }
  },

  methods: {
    downloadPdf() {
      this.isShowPdf = true
      const HTML = this.$refs.pdfComponent.$el

      let pdf = new jsPDF( {
        orientation: "portrait",
        format: "a4",
        unit: "px"
      } )

      // $('#pdf').css({
      //   width: pdf.internal.pageSize.width,
      //   height: pdf.internal.pageSize.height * $('.pdf-page').length - 1
      // }).attr('data-pdf-page-height', pdf.internal.pageSize.height)
      // $('.pdf-page').css({
      //     height: pdf.internal.pageSize.height,
      // })
      // $('#pdf').css({ display: 'block' })
      pdf.html( HTML, {
        callback: ( pdf ) => {
          pdf.save( "receipt.pdf" )
          this.isShowPdf = false
        }
      } )
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
//.HistoryDetails_root {
//  border: 2px solid #213b57;
//  box-shadow: 0px 18px 34px rgba(34, 39, 47, 0.55);
//  border-bottom-left-radius: 4px;
//  border-bottom-right-radius: 4px;
//}
//.HistoryDetails_wrapper {
//  padding: 19px 10px;
//  @media only screen and (min-width: $tablet-width) {
//    padding: 19px 0px 19px 15px;
//  }
//}
.HistoryDetails {
  &_id {
    color: #fff;
  }

  &_greenColor {
    color: #5ABC67;
  }

  &_redColor {
    color: #FF556D;
  }

  &_blueColor {
    color: #1C8AE4;
  }

  &_purpleColor {
    color: #A44FD5;
  }
}
</style>