<template>
  <div class="HistoryDetailsDefault_container">
    <div class="HistoryDetailsDefault_row">
      <span>Fee:</span><span>{{ feeFormatted }} {{ order.currency }}</span>
    </div>
    <div class="HistoryDetailsDefault_row">
      <span>Hash:</span><span>{{ order.details.tr_hash }}</span>
    </div>
    <div
      class="HistoryDetailsDefault_row"
      v-if="formatedAccauntBalance"
    >
      <span>Account balance:</span><span>{{ formatedAccauntBalance }}</span>
    </div>
  </div>
</template>

<script>
import { roundingCurrency } from '@/utils/helpers';

export default {
  props: {
    order: {
      type: [Object, Array],
      required: true,
    },
  },
  computed: {
    feeFormatted() {
      return roundingCurrency({
        currencyAmount: this.order.details.fee,
        currency: this.order.currency,
      })
    },
    formatedAccauntBalance() {
      if(this.order.account_balance) {
        return Object.entries(this.order.account_balance).map(([currency, currencyAmount]) => `${roundingCurrency({ currencyAmount, currency })} ${currency}`).join(', ')
      }
      return false
    },
  },
};
</script>
