<template>
  <div class="crypto-withdraw-details-container">
    <div
      class="text-left break-all"
      v-if="order.pay_currency"
    >
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Currency to pay:</span>
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ order.pay_currency }}</span>
    </div>
    <div
      class="text-left break-all"
      v-if="order.pay_amount"
    >
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Amount to pay:</span>
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ order.pay_amount }}</span>
    </div>
    <div
      class="text-left break-all"
      v-if="order.internal_id"
    >
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Internal id:</span>
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ order.internal_id }}</span>
    </div>
    <div
      class="text-left break-all"
      v-if="details.source_account"
    >
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Source account:</span>
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ details.source_account }}</span>
    </div>
    <div
      class="text-left break-all"
      v-if="details.customer_email">
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Customer email:</span>
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ details.customer_email }}</span>
    </div>
    <div
      class="text-left break-all"
      v-if="details.destination_account"
    >
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Destination account:</span>
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ details.destination_account }}</span>
    </div>
    <div
      class="text-left break-all"
      v-if="details.comment">
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Comment:</span>
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ details.comment }}</span>
    </div>
    <div
      class="text-left break-all"
      v-if="formatedAccauntBalance"
    >
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px]">Account balance:</span>
      <span class="odd:mr-[5px] odd:text-gray-600 text-[12px] leading-[14px] mr-[15px] 2xl:text-[14px] 2xl:leading-[16px] 3xl:text-[16px] 3xl:leading-[19px] text-blue-700 dark:text-white">{{ formatedAccauntBalance }}</span>
    </div>
  </div>
</template>

<script>
import { roundingCurrency } from "@/utils/helpers";

export default {
  props: {
    order: {
      type: [Object, Array],
      required: true,
    },
  },
  computed: {
    formatedAccauntBalance() {
      if(this.order.account_balance) {
        return Object.entries(this.order.account_balance).map(([currency, currencyAmount]) => `${roundingCurrency({ currencyAmount, currency })} ${currency}`).join(', ')
      }

      return false
    },
    details() {
      return this.order.details;
    },
  },
};
</script>
