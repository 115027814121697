<template>
  <div class="shadow-history-row-shadow rounded-[3px] overflow-visible relative h-full bg-[#F6F6F6]/[0.7] dark:bg-blue-800/[0.44] dark:shadow-providers-history-row-root md:min-w-[370px]">
    <ExchangeTabs
      :activeTab="activeExchangeTab"
      @updateTab="handleChangeTab"
    />
    <div class="py-[20px] px-[10px] md:p-[20px]">
      <div
        class="exchange-skeleton"
        v-if="isBalancesPending"
      >
        <v-skeleton-loader
          key="skeleton"
          transition-group="fade-transition"
          type="table-cell@5"
        />
      </div>
      <div v-if="isBalancesLoaded && !confirmStep">
        <ExchangeMarket
          v-if="activeExchangeTab === 'market'"
          @setConfirmStep="handleConfirmExchangeStep"
        />
        <ExchangeLimit
          v-if="activeExchangeTab === 'limit'"
          @setConfirmStep="handleConfirmExchangeStep"
        />
      </div>
      <ConfirmExchange
        :exchangeType="activeExchangeTab"
        v-if="confirmStep"
        @submitExchange="handleSubmitExchange"
        @cancelConfirm="handleCancelConfirmExchange"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import ExchangeTabs from './ExchangeTabs/ExchangeTabs';
import ExchangeMarket from './ExchangeMarket/ExchangeMarket';
import ExchangeLimit from './ExchangeLimit/ExchangeLimit';
import ConfirmExchange from './ConfirmExchange/ConfirmExchange';

export default {
  components: {
    ExchangeTabs,
    ExchangeMarket,
    ExchangeLimit,
    ConfirmExchange,
  },
  data: () => ({
    confirmStep: false,
  }),
  computed: {
    ...mapGetters('balance', ['isBalancesPending', 'isBalancesLoaded']),
    ...mapState('exchange', ['activeExchangeTab']),
  },
  methods: {
    ...mapActions('exchange', ['apiPostExchangeRequest', 'stopListeningToCoinsPairRateAndChangeExchangeActiveTab']),
    handleChangeTab(tab) {
      if (!this.confirmStep) {
        this.stopListeningToCoinsPairRateAndChangeExchangeActiveTab(tab)
      }
    },
    handleConfirmExchangeStep() {
      this.confirmStep = true
    },
    handleSubmitExchange() {
      this.apiPostExchangeRequest(this.activeExchangeTab).then(() => (this.confirmStep = false))
    },
    handleCancelConfirmExchange() {
      this.confirmStep = false
    },
  },
};
</script>

<style lang="scss">
.exchange-skeleton {
  .v-skeleton-loader {
    .v-skeleton-loader__table-cell {
      width: 100%;
      margin-bottom: 15px;
      &:nth-of-type(1n) {
        height: 70px;
        .v-skeleton-loader__text {
          height: 70px;
        }
      }
      &:nth-of-type(2) {
        height: 30px;
        .v-skeleton-loader__text {
          height: 30px;
        }
      }
      &:nth-of-type(3) {
        height: 70px;
      }
      &:nth-of-type(4) {
        height: 70px;
      }
      &:nth-of-type(5) {
        height: 95px;
        margin-bottom: 0;
        .v-skeleton-loader__text {
          height: 95px;
        }
      }
    }
  }
}
</style>
